import { useRouter as useMasterRouter } from 'next/router'
import { useCallback } from 'react'

import { getSubPath, isSameZone } from './utils'

interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
  unstable_skipClientCache?: boolean
}

export const useRouter = () => {
  const {
    push: masterPush,
    replace: masterReplace,
    basePath,
    reload,
    back,
    route,
    pathname,
    query,
  } = useMasterRouter()

  const push = useCallback<
    (
      url: string,
      as?: string | undefined,
      options?: TransitionOptions | undefined,
    ) => Promise<boolean>
  >(
    async (href: string, ...rest) => {
      const sameZone = isSameZone(href, basePath)

      if (!sameZone) {
        window.location.href = href
        return true
      }
      return masterPush(getSubPath(href, basePath), ...rest)
    },
    [masterPush],
  )

  const replace = useCallback<
    (
      url: string,
      as?: string | undefined,
      options?: TransitionOptions | undefined,
    ) => Promise<boolean>
  >(
    async (href: string, ...rest) => {
      const sameZone = isSameZone(href, basePath)

      if (!sameZone) {
        window.location.replace(href)
        return true
      }
      return masterReplace(getSubPath(href, basePath), ...rest)
    },
    [masterReplace],
  )

  return { basePath, replace, push, reload, back, route, pathname, query }
}
