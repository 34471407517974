export const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL
export const GRAPHQL_URL = `${BACKEND_URL}/graphql/`
export const PUBLIC_BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL || 'https://www.stylespace.com'
export const IMAGE_BASE_URL =
  process.env.NEXT_PUBLIC_IMAGE_URL || 'https://image.stylespace.com'
export const ENV = process.env.NEXT_PUBLIC_ENV || 'PROD'
export const IS_MOCK = ENV === 'MOCK'
export const IS_DEV = process.env.NODE_ENV === 'development'
export const IS_TEST = process.env.NODE_ENV === 'test'
export const TOKEN = `authJsonToken${ENV}`
export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN
export const CANONICAL =
  process.env.NEXT_PUBLIC_CANONICAL || 'https://www.stylespace.com'
export const AWS_URL = process.env.NEXT_PUBLIC_AWS_URL || ''

export const ASPECT_3x4 = 3 / 4
