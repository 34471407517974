var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const ENV = process.env.NEXT_PUBLIC_ENV
const SAMPLE_RATE = process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || 0

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: Number(SAMPLE_RATE),
  environment: ENV,
})
