import styled, { css } from 'styled-components'
import NextImage from 'next/image'

import { Button } from '../Button'
import { pxToRem } from '../lib/utils'
import { NextLink } from '../lib/router'

export const SFooter = styled.footer(
  ({ theme }) => css`
    width: 100%;
    padding: 7.8125rem 1.25rem 2rem;
    box-sizing: border-box;
    background-color: ${theme.color.grey['800']};

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      padding: 4rem 1rem 2rem;

      &.bottom-padding {
        padding: 4rem 1rem 6rem;
      }
    }
  `,
)

export const SImage: any = styled(NextImage)(
  ({ theme }) => css`
    width: ${pxToRem(60)};
    height: auto;

    @media only screen and (min-width: ${theme.breakpoint.tabletV}) {
      width: ${pxToRem(80)};
    }
  `,
)

export const CertWrap = styled.div(
  ({ theme }) => css`
    margin-top: 1rem;
    display: flex;

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      justify-content: center;
    }
  `,
)

export const InnerWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    max-width: ${theme.maxScreen};
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
  `,
)

export const Line = styled.div(
  ({ theme }) => css`
    position: absolute;
    width: 1.25rem;
    height: 0.125rem;
    background-color: ${theme.color.grey['200']};
    top: -1.5rem;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: none;
    }
  `,
)

export const Logo = styled.img(
  () => css`
    width: 0.625rem;
    height: auto;
    margin-right: 0.875rem;
  `,
)

export const Icon = styled.img(
  () => css`
    width: auto;
    height: ${pxToRem(14)};
  `,
)

export const LeftWrap = styled.div``

export const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const LogoText = styled.div(
  ({ theme }) => css`
    color: ${theme.color.white};
    font-size: ${pxToRem(13)};
    line-height: 1;
    margin: 0;
  `,
)

export const Nav = styled.nav`
  display: block;
`

export const LinkWrap = styled.div`
  display: flex;

  > nav:first-of-type {
    margin-right: 6.1875rem;
  }
`

export const LinkTitle = styled.div(
  ({ theme }) => css`
    font-weight: ${theme.font.weight.semibold};
    font-size: ${pxToRem(16)};
    line-height: 1;
    color: ${theme.color.white};
    margin-bottom: 1.875rem;
  `,
)

export const Link = styled(NextLink)(
  ({ theme }) => css`
    display: block;
    font-weight: ${theme.font.weight.semibold};
    font-size: ${pxToRem(14)};
    line-height: 1;
    color: ${theme.color.white};
    text-decoration: none;
    margin-bottom: 1rem;

    :hover,
    :focus {
      text-decoration: underline;
    }
  `,
)

export const SButton = styled(Button)(
  ({ theme }) => css`
    && {
      line-height: 1;
      margin-bottom: 1rem;
      font-weight: ${theme.font.weight.semibold};
      color: ${theme.color.white};
      text-align: left;
      display: block;
      font-size: ${pxToRem(14)};
    }
  `,
)

export const SocialWrap = styled.div`
  margin-bottom: 2rem;
`

export const SocialLink = styled.a`
  :first-of-type {
    margin-right: 1.875rem;
  }

  :last-of-type {
    margin-left: 1.875rem;
  }
`
