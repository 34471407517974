import crossFetch from 'cross-fetch'

import { GraphQlError } from '../error/GraphQlError'
import { NETWORK_ERROR } from './error'

export type GraphqlResponseError = { message: string }
export type GraphqlResponse<T> = { data: T; errors: GraphqlResponseError[] }

/**
 * Simple js fetch wrapper for graphql request
 * @param url Endpoint url
 * @param query GraphQl query
 * @param headers Request headers
 * @param variables Graphql variables
 *
 * @returns data Endpoint response
 */
export const fetch = async <T = any>(
  url: string,
  query: string,
  headers: { [key: string]: string } = {},
  variables: { [key: string]: string | number } = {},
  signal?: AbortSignal,
): Promise<GraphqlResponse<T>> => {
  return await crossFetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      ...headers,
    },
    body: JSON.stringify({
      query: query.trim().split(/\s+/).join(' '),
      variables,
    }),
    signal,
  })
    .then(async (res) => {
      return res.json()
    })
    .catch((e) => {
      if (e.message === NETWORK_ERROR) {
        return {
          data: null,
          errors: [{ message: NETWORK_ERROR }],
        }
      }
      throw new GraphQlError(`${query.slice(0, 10)}...`, e)
    })
}
