import React from 'react'
import * as Sentry from '@sentry/nextjs'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { GraphQlError } from '../error/GraphQlError'
import { ENV } from '../utils'
import { isWrongToken, NETWORK_ERROR } from './error'
import { removeToken } from './token'

const retryLimit = 5
const retry = (failureCount: number, error: any) => {
  if (retryLimit < failureCount) {
    return false
  }
  if (error?.message === NETWORK_ERROR) {
    return true
  }

  if (error instanceof GraphQlError) {
    const { message } = error.errors[0]
    if (isWrongToken(message)) {
      Sentry.captureException(new Error('Token is wrong'))
      removeToken()
      return false
    }
  }

  return false
}

const retryDelay = (failureCount: number, error: any) => {
  if (error?.message === NETWORK_ERROR) {
    return Math.min(500 * 2 ** failureCount, 3000)
  }
  return 500
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry,
      retryDelay,
    },
    mutations: {
      retry,
      retryDelay,
    },
  },
})

export const GraphqlProvider: React.FC<{
  state?: any
  children: React.ReactNode
}> = ({ children, state }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={state}>{children}</Hydrate>
      {ENV === 'DEMO' && <ReactQueryDevtools />}
    </QueryClientProvider>
  )
}
