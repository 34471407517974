import Cookies from 'js-cookie'

import { getDomain } from '../../lib/utils'
import { CookieConsent } from './types'

const COOKIE_KEY = 'COOKIE_CONSENT'
export const COOKIE_UPDATE_EVENT = 'consent_update'

export const setCookieConsent = (values: CookieConsent) => {
  Cookies.set(COOKIE_KEY, JSON.stringify(values), {
    domain: getDomain(),
    expires: 365,
  })
}

export const getCookieConsent = (): CookieConsent | null | undefined => {
  if (typeof window === 'undefined') {
    return undefined
  }
  const stringCookieConsent = Cookies.get(COOKIE_KEY)
  if (!stringCookieConsent) {
    return null
  }
  try {
    return JSON.parse(stringCookieConsent)
  } catch (e: any) {
    console.error(e)
    return null
  }
}
