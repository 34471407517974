import React, { HTMLAttributes, useContext } from 'react'
import { useField } from 'formik'
import { ThemeContext } from 'styled-components'

import {
  Wrap,
  CheckboxWrap,
  Ball,
  Label,
  HiddenInput,
} from './SlideChecbox.style'
import { DefaultTheme } from '../../themes'

type Props = HTMLAttributes<HTMLInputElement> & {
  id: string
  label: string
  name: string
  disabled?: boolean
  className?: string
}

export const SlideCheckbox: React.FC<Props> = ({
  label,
  id,
  disabled,
  name,
  className = '',
  ...rest
}) => {
  const [field] = useField(name)
  const theme = useContext(ThemeContext) || DefaultTheme

  const checkedClass = field.value ? 'checked ' : ''
  const disabledClass = disabled ? 'disabled ' : ''
  return (
    <Wrap
      theme={theme}
      data-testid="slideCheckbox"
      className={`${className} ${disabledClass}`}
    >
      <Label theme={theme} htmlFor={id}>
        {label}
      </Label>
      <CheckboxWrap theme={theme} className={checkedClass}>
        <Ball theme={theme} className={checkedClass} />
        <HiddenInput
          theme={theme}
          disabled={disabled}
          data-testid="slideCheckboxInput"
          id={id}
          checked={field.value}
          {...field}
          type="checkbox"
          {...rest}
        />
      </CheckboxWrap>
    </Wrap>
  )
}
SlideCheckbox.displayName = 'SlideCheckbox'
