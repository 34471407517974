import styled, { css } from 'styled-components'

import { pxToRem } from '../lib/utils'

export const SButton = styled.button(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: ${pxToRem(11, 23)};
    height: max-content;
    font-size: ${pxToRem(16)};
    line-height: 1.5;
    box-shadow: none;
    text-align: center;
    font-weight: ${theme.font.weight.bold};
    cursor: pointer;
    border: ${pxToRem(1)} dotted transparent;
    border-radius: ${theme.radius.small};
    :hover {
      border-color: ${theme.color.grey['700']};
    }
    :focus-visible {
      border-color: ${theme.color.grey['700']};
    }
    :focus {
      outline: none;
    }
    :disabled {
      cursor: default;
      background-color: ${theme.color.grey['100']};
      border: ${pxToRem(1)} solid ${theme.color.grey['300']};
      color: ${theme.color.grey['400']};
    }

    &.iconOnly {
      padding: ${pxToRem(13)};
      img,
      svg {
        margin-right: ${pxToRem(0)};
      }
    }

    &.primary {
      color: ${theme.color.white};
      background-color: ${theme.color.primary['500']};
      :hover {
        background-color: ${theme.color.primary['400']};
        border: ${pxToRem(1)} solid ${theme.color.primary['400']};
      }
      :focus-visible {
        border: ${pxToRem(1)} solid ${theme.color.primary['500']};
        background-color: ${theme.color.primary['500']};
        box-shadow: ${theme.boxShadow.focusBoxShadow};
        :hover {
          border: ${pxToRem(1)} solid ${theme.color.primary['400']};
          background-color: ${theme.color.primary['400']};
        }
      }
      :disabled {
        cursor: default;
        background-color: ${theme.color.grey['100']};
        border: ${pxToRem(1)} solid ${theme.color.grey['300']};
        color: ${theme.color.grey['400']};
      }
    }

    &.secondary {
      color: ${theme.color.grey['500']};
      background-color: ${theme.color.white};
      border: ${pxToRem(1)} solid ${theme.color.grey['300']};
      :hover {
        color: ${theme.color.grey['500']};
        background-color: ${theme.color.white};
        border: ${pxToRem(1)} solid ${theme.color.grey['400']};
      }
      :focus-visible {
        border: ${pxToRem(1)} solid ${theme.color.grey['300']};
        box-shadow: ${theme.boxShadow.focusBoxShadow};
        :hover {
          border: ${pxToRem(1)} solid ${theme.color.grey['400']};
        }
      }
      :disabled {
        cursor: default;
        background-color: ${theme.color.grey['100']};
        border: ${pxToRem(1)} solid ${theme.color.grey['300']};
        color: ${theme.color.grey['400']};
      }
    }

    &.redButton {
      background-color: ${theme.color.red['500']};
      border: ${pxToRem(1)} solid ${theme.color.red['500']};
      color: ${theme.color.white};
      :hover {
        background-color: ${theme.color.red['400']};
        border: ${pxToRem(1)} solid ${theme.color.red['400']};
      }
      :focus-visible {
        border: ${pxToRem(1)} solid ${theme.color.grey['300']};
        box-shadow: ${theme.boxShadow.focusBoxShadow};
        :hover {
          border: ${pxToRem(1)} solid ${theme.color.grey['400']};
        }
      }
      :disabled {
        cursor: default;
        background-color: ${theme.color.grey['100']};
        border: ${pxToRem(1)} solid ${theme.color.grey['300']};
        color: ${theme.color.grey['400']};
      }
    }

    &.inline-link {
      height: auto;
      color: ${theme.color.primary['500']};
      font-weight: inherit;
      display: inline;
      font-size: inherit;
      margin: 0;
      padding: 0;
      border: ${pxToRem(1)} dotted transparent;
      text-decoration: none;
      :hover {
        border-color: transparent;
        background-color: transparent;
        text-decoration: underline;
      }
      :focus-visible {
        border: ${pxToRem(1)} dotted ${theme.color.grey['700']};
      }
    }

    &.link {
      height: auto;
      display: block;
      color: ${theme.color.primary['500']};
      font-weight: inherit;
      font-size: inherit;
      margin: 0;
      padding: 0;
      border: ${pxToRem(1)} dotted transparent;
      text-decoration: none;
      :hover {
        border-color: transparent;
        background-color: transparent;
        text-decoration: underline;
      }
      :focus-visible {
        border: ${pxToRem(1)} dotted ${theme.color.grey['700']};
      }
    }

    &.regular {
      padding: ${pxToRem(11, 23)};
      font-size: ${pxToRem(16)};
      line-height: 1.5;
      &.iconOnly {
        padding: ${pxToRem(13)};
        img,
        svg {
          margin-right: ${pxToRem(0)};
        }
      }
    }
    &.small {
      padding: ${pxToRem(7, 15)};
      font-size: ${pxToRem(14)};
      line-height: 1.43;
      &.iconOnly {
        padding: ${pxToRem(9)};
        img,
        svg {
          margin-right: ${pxToRem(0)};
        }
      }
      svg,
      img {
        width: ${pxToRem(16)};
        height: ${pxToRem(16)};
        margin-right: ${pxToRem(8)};
      }
    }

    &.uppercase {
      text-transform: uppercase;
    }

    svg,
    img {
      width: ${pxToRem(20)};
      height: ${pxToRem(20)};
      margin-right: ${pxToRem(12)};
    }
  `,
)
