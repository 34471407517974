import styled, { css } from 'styled-components'

import { pxToRem } from '../../lib/utils'

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const Label = styled.label(
  ({ theme }) => css`
    color: ${theme.color.grey['700']};
    font-size: ${pxToRem(14)};
    font-weight: ${theme.font.weight.semibold};

    .disabled & {
      color: ${theme.color.grey['500']};
    }
  `,
)

export const CheckboxWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    display: inline-block;
    padding: 0;
    width: 1.5rem;
    flex: none;
    height: 0.6875rem;
    border: 0.0625rem solid transparent;
    box-shadow: none;
    border-radius: 0.375rem;
    background-color: ${theme.color.grey['300']};

    :focus-within {
      border-color: ${theme.color.grey['700']};
      outline: none;
    }

    &.checked {
      background-color: ${theme.color.primary['500']};
    }

    .disabled & {
      opacity: 0.6;
    }
  `,
)

export const HiddenInput = styled.input`
  position: absolute;
  left: 50%;
  cursor: pointer;
  top: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  width: ${pxToRem(44)};
  height: ${pxToRem(44)};
  opacity: 0;

  .disabled & {
    cursor: initial;
  }
`

export const Ball = styled.div(
  ({ theme }) => css`
    position: absolute;
    width: 0.5625rem;
    height: 0.5625rem;
    border-radius: 50%;
    left: 0;
    top: calc(50% - 0.28125rem);
    background-color: ${theme.color.white};
    transition: left 150ms;

    &.checked {
      left: 0.8125rem;
    }
  `,
)
