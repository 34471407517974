import { Category, Gender } from '../../types'

export const GIFT_UP_ROUTE = process.env.NEXT_PUBLIC_GIFT_UP_ROUTE

export const EXTERNAL_ROUTES = {
  infoEmail: 'mailto:info@stylespace.com',
  supportEmail: 'mailto:support@stylespace.com',
  stylistEmail: 'mailto:stylists@stylespace.com',
  customerEmail: 'mailto:customer@stylespace.com',
  zoomTestCall: 'https://zoom.us/test',
  facebook: 'https://www.facebook.com/stylespace.inc',
  instagram: 'https://www.instagram.com/stylespace.official',
  speedTest: 'https://www.speedtest.net/',
  stripe: 'https://stripe.com/',
  base: 'https://www.stylespace.com/',
  business: 'https://workforce.stylespace.com/',
  veteran: 'https://veterans.stylespace.com/',
}

export const LEGAL_DOCUMENTS_ROUTES = {
  cookiePolicy: `/docs/cookie-policy`,
  privacyPolicy: `/docs/privacy-policy`,
  termsOfService: `/docs/terms-of-service`,
  cancellationPolicy: `/docs/terms-of-service#cancel-refund?refund=true`,
  refundPolicy: `/docs/terms-of-service#cancel-refund`,
  stylistTermsOfService: `/docs/stylist-terms-of-service`,
  GDPRPolicy: `/docs/GDPR-policy`,
}

export const ROUTES = {
  home: '/',
  search: '/search',
  permissionError: `/permission-error`,
  about: `/about`,
  becomeStylist: `/join_us`,
  howItWorks: `/how-it-works`,
  contact: `/contact`,
  faq: `/faq`,
  sitemap: `/sitemap`,
  blog: '/blog',
}

export const STYLIST_ROUTES = {
  init: `/switch-to-stylist`,
  joinUsStatus: `/join_us/status`,
  account: `/account`,
  profile: `/stylist-profile`,
  bookings: `/bookings`,
  sessions: `/stylist/my`,
}

export const CUSTOMER_ROUTES = {
  init: `/switch-to-customer`,
  account: `/account`,
  profile: `/profile`,
  bookings: `/bookings`,
}

export const ADMIN_ROUTES = {
  applications: `/admin/applications`,
  application: `/admin/stylist_application`,
  bookings: `/admin/bookings`,
}

export const getBookingRoute = (id: string) => `/booking?id=${id}`
export const getCustomerRoute = (id: string) => `/user-profile?id=${id}`
export const getStylistSlugRoute = (slug: string) => `/stylist/${slug}`
export const getChatRoute = (uid?: string) => `/chat${uid ? `?uid=${uid}` : ''}`

export const getLogoutRoute = (path?: string) => {
  const { pathname, search } = window.location
  return `/logout?path=${encodeURIComponent(
    path ? path : `${pathname}${search}`,
  )}`
}

export const getSignInRoute = (path?: string) => {
  const route = path ? path : window.location.href
  return `/sign-in?route=${encodeURIComponent(route)}`
}

export const getSitemapRoute = (tab?: string) => {
  if (!tab) {
    return `${ROUTES.sitemap}/general`
  }
  return `${ROUTES.sitemap}/${tab}`
}

export const getFAQRoute = (tab?: string) => {
  if (!tab) {
    return `${ROUTES.faq}/general`
  }
  return `${ROUTES.faq}/${tab}`
}

export const SEARCH_GENDER_MAP: { [key in Gender]: string } = {
  FEMALE: 'female',
  MALE: 'male',
  ANY: 'any',
}

export const SEARCH_GENDER_TO_MAP = Object.entries(SEARCH_GENDER_MAP).reduce(
  (res, [key, value]) => ({ ...res, [value]: key as Gender }),
  {} as { [key: string]: Gender },
)

export const SEARCH_CATEGORY_MAP: { [key in Category]: string } = {
  CLOTHING: 'clothing',
  HAIR: 'hair',
  MAKE_UP_SKIN_CARE: 'makeup',
  ANY: 'any',
}

export const SEARCH_CATEGORY_TO_MAP = Object.entries(
  SEARCH_CATEGORY_MAP,
).reduce(
  (res, [key, value]) => ({ ...res, [value]: key as Category }),
  {} as { [key: string]: Category },
)

export const getSearchRoute = (
  style: Category = 'CLOTHING',
  gender: Gender = 'ANY',
  tags: string[] = [],
) => {
  const searchParams = new URLSearchParams({
    category: SEARCH_CATEGORY_MAP[style],
    gender: SEARCH_GENDER_MAP[gender],
    ...(tags.length > 0 ? { tags: tags.join(',') } : {}),
  })
  return `${ROUTES.search}?${searchParams.toString()}`
}
