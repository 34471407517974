import React from 'react'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

import { getSubPath, isSameZone } from './utils'

type Props = LinkProps & {
  href: string
  target?: '_blank'
  className?: string
  children: React.ReactNode
}

export const NextLink: React.FC<Props> = ({ href, children, ...rest }) => {
  const router = useRouter()

  const sameZone = isSameZone(href, router?.basePath)
  if (!sameZone) {
    return (
      <a href={href} {...rest}>
        {children}
      </a>
    )
  }

  return (
    <Link href={getSubPath(href, router?.basePath)} {...rest}>
      {children}
    </Link>
  )
}
NextLink.displayName = 'NextLink'
