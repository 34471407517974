import styled, { css } from 'styled-components'

import { pxToRem } from '../lib/utils'

export const Header = styled.div(
  ({ theme }) => css`
    position: relative;
    top: 0;
    font-family: ${theme.font.poppins};
    color: ${theme.color.grey['700']};
    margin: 0;
    font-weight: ${theme.font.weight.regular};

    &.style1 {
      font-size: ${pxToRem(64)};
      line-height: 1.125;
    }
    &.style2 {
      font-size: ${pxToRem(40)};
      line-height: 1.2;
    }
    &.style3 {
      font-size: ${pxToRem(32)};
      line-height: 1.125;
    }
    &.style4 {
      font-size: ${pxToRem(24)};
      line-height: 1.125;
    }
    &.style5 {
      font-size: ${pxToRem(20)};
      line-height: 1.2;
    }
    &.style6 {
      font-size: ${pxToRem(18)};
      line-height: ${pxToRem(20)};
    }

    &.line {
      top: 0.5rem;
    }
  `,
)

export const Line = styled.div(
  ({ theme }) => css`
    width: 1.25rem;
    height: 0.125rem;
    position: absolute;
    top: -0.5rem;
    background-color: ${theme.color.primary['500']};
  `,
)
