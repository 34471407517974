import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Wrap, ControlWrap, SInlineLink } from './CookieInfo.style'
import { DefaultProps } from '../../types'
import { LEGAL_DOCUMENTS_ROUTES } from '../../lib/utils'
import { DefaultTheme } from '../../themes'
import { Button } from '../../Button'

type Props = DefaultProps & {
  onMore(): void
  onAgree(): void
}

export const CookieInfo: React.FC<Props> = ({
  className = '',
  onMore,
  onAgree,
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <Wrap theme={theme} data-testid="cookie-info" className={className}>
      We use cookies (including third party cookies) to provide you with a
      better user experience by being more responsive and personalized, such as
      enhancing website navigation, analyzing website usage and to enhance the
      user experience and assisting in our marketing efforts
      <br />
      <SInlineLink
        anchorType="inline"
        underline
        theme={theme}
        data-testid="cookie-info-cookie-policy"
        target="_blank"
        href={LEGAL_DOCUMENTS_ROUTES.cookiePolicy}
      >
        Cookie policy
      </SInlineLink>
      <SInlineLink
        anchorType="inline"
        underline
        theme={theme}
        data-testid="cookie-info-privacy-policy"
        target="_blank"
        href={LEGAL_DOCUMENTS_ROUTES.privacyPolicy}
      >
        Privacy policy
      </SInlineLink>
      <ControlWrap theme={theme}>
        <Button
          buttonType="secondary"
          buttonSize="small"
          data-testid="cookie-info-more"
          onClick={onMore}
        >
          MORE
        </Button>
        <Button
          buttonType="primary"
          buttonSize="small"
          data-testid="cookie-info-agree"
          onClick={onAgree}
        >
          AGREE
        </Button>
      </ControlWrap>
    </Wrap>
  )
}
