export const compareRecords = (
  obj1: Record<string, string | number | boolean | string[]>,
  obj2: Record<string, string | number | boolean | string[]>,
) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export const deSlugify = (value: string) => {
  if (typeof value === 'string') {
    return value.split('-').join(' ')
  }
  return value
}

export const slugify = (value: string) => {
  if (typeof value === 'string') {
    return value.split(' ').join('-')
  }
  return value
}

export const getDomain = (hostName: string = window.location.hostname) => {
  const parts = hostName.split('.')
  let ret = ''
  const lastIndex = parts.length - 1
  for (let i = lastIndex; i > lastIndex - 3; i -= 1) {
    const part = parts[i]
    if (!part) {
      break
    }
    if (i === lastIndex) {
      ret = part
      continue
    }
    if (part === 'www') {
      continue
    }
    ret = part + '.' + ret
  }
  return ret
}

export const aspectToRatio = (aspect: number) => Math.floor((1 / aspect) * 100)
