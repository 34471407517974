import { AppMode, AuthAction, TimezoneInfo, User } from './types'

export const getUpdateUserAction = (user: User | null): AuthAction => {
  return {
    type: 'updateUser',
    payload: user,
  }
}

export const getUpdateModeAction = (mode: AppMode | null): AuthAction => {
  return {
    type: 'updateMode',
    payload: mode,
  }
}

export const getUpdateTimezoneAction = (
  mode: TimezoneInfo | null,
): AuthAction => {
  return {
    type: 'updateTimezone',
    payload: mode,
  }
}
