import { useEffect } from 'react'

import { useConsent } from '../consent'
import { identify, initMixpanel, reset, track } from './utils'

export const useMixpanel = () => {
  const { consent } = useConsent()
  useEffect(() => {
    if (consent?.analytics && typeof window !== undefined) {
      initMixpanel()
    }
  }, [consent?.analytics])

  return {
    trackEvent: track,
    track,
    identify,
    reset,
  }
}
