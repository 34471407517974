import { useCallback, useContext, useEffect } from 'react'
import { useQuery as useReactQuery, UseQueryResult } from 'react-query'

import { AuthContext } from '../../Context/AuthContext/AuthContext'
import { selectUser } from '../../Context/AuthContext/selectors'
import { GraphQlError, GraphqlResponseError } from '../error/GraphQlError'
import { GRAPHQL_URL } from '../utils'
import { getToken } from './token'
import { QueryKey, useQueryFn } from './useQueryFn'

export type AnyVariables = { [key: string]: string | number | boolean }

export type FetchParams<V = AnyVariables> = {
  url?: string
  query?: string
  headers?: { [key: string]: string }
  variables?: V
}

export type HookResponse<T> = UseQueryResult<T> & {
  refetch: () => Promise<T | null>
  loading: boolean
  data: T | null
  errors: GraphqlResponseError[] | null
  error: string | null
  key: any
}

/**
 * Wrapper around use React query
 * @param query Graphql query
 * @param options Additional options
 * @param options.run Run automatically
 * @param options.url Endpoint url, default ENV used if not specified
 * @param options.headers Request headers
 * @param options.variables Graphql variables
 */
export const useQuery = <T = any, V extends AnyVariables = AnyVariables, Y = T>(
  query: string,
  options: FetchParams<V> & {
    run?: boolean
    key?: string
    ignoreGraphqlErrors?: boolean
  } = {},
  queryOptions: {
    keepPreviousData?: boolean
    onSuccess?: (data: Y) => void
    onSettled?: () => void
    select?: (data: T) => Y
    refetchInterval?: number
  } = {},
) => {
  const {
    key,
    ignoreGraphqlErrors = false,
    run = true,
    url = GRAPHQL_URL,
    headers = {},
    variables = {} as V,
  } = options
  const { state } = useContext(AuthContext)
  const user = selectUser(state)
  const queryCacheKey: QueryKey = [key || query.slice(0, 50), variables]
  const token = getToken()
  useEffect(() => {
    if (!token && user) {
      window.location.reload()
    }
  }, [token])
  const {
    refetch: refetchQuery,
    isLoading,
    data,
    error,
  } = useReactQuery<T, GraphQlError, Y, QueryKey>(
    queryCacheKey,
    useQueryFn<T>({
      url,
      headers,
      query,
      ignoreGraphqlErrors,
    }),
    {
      enabled: run,
      ...queryOptions,
    },
  )

  const refetch = useCallback(async (): Promise<Y | null> => {
    const { data } = await refetchQuery({ cancelRefetch: true })
    return data || null
  }, [refetchQuery])

  const errors = error?.errors || null

  return {
    refetch,
    loading: isLoading,
    data: data || null,
    errors,
    error: errors?.length ? errors[0].message : null,
    key: queryCacheKey,
  }
}
