export const isSameZone = (href: string, basePath: string | undefined) => {
  if (!basePath || basePath === '/') {
    return true
  }
  const regex = new RegExp(`^${basePath}(\\?|\\/|$)+.*`)
  return regex.test(href)
}

export const getSubPath = (href: string, basePath: string | undefined) => {
  if (!basePath || basePath === '/') {
    return href
  }
  if (!isSameZone(href, basePath)) {
    return href
  }

  const regex = new RegExp(`^${basePath}((\\?|\\/|$)+.*)`)
  const path = regex.exec(href)![1]
  return path.startsWith('/') ? path : `/${path}`
}
