import React, { useContext } from 'react'
import * as Sentry from '@sentry/nextjs'

import { AuthContext } from '../../AuthContext'
import { selectUser } from '../../selectors'
import { getUpdateUserAction } from '../../actions'
import { User } from '../../types'
import { getToken } from '../../../../lib/graphql/token'
import { useQuery } from '../../../../lib/graphql/useQuery'
import { setTimezone } from '../../../../lib/time'
import { ArgumentError } from '../../../../lib/error'
import { identify } from '../../../../lib/mixpanel'
import { QUERY } from './query'
import { UserQueryData } from './types'
import { transformData } from './utils'

type UseUserResponse = {
  error: string | null
  data: User | null
  refetch: () => Promise<UserQueryData | null>
  loading: boolean
}

export const useUser = (): UseUserResponse => {
  const { state, dispatch } = useContext(AuthContext)
  const token = getToken()
  const user = selectUser(state)
  const timezone = user?.timezone
  const { refetch, loading, error } = useQuery<UserQueryData>(
    QUERY,
    {
      run: !Boolean(user) && Boolean(token),
      key: 'userUserAuth',
    },
    {
      onSuccess: (data) => {
        if (!data || !data?.user?.id) {
          return
        }
        dispatch(getUpdateUserAction(transformData(data.user)))
      },
    },
  )

  React.useEffect(() => {
    if (user?.id) {
      const { fullName } = user
      identify(user.id)
      Sentry.setUser({
        id: user?.id,
        username: fullName,
      })
    }
  }, [user?.id])

  React.useEffect(() => {
    if (!timezone) {
      return
    }
    setTimezone(timezone)
  }, [timezone])

  if (error) {
    throw new ArgumentError('useUser', `${error}`)
  }

  return {
    data: user,
    refetch,
    loading,
    error,
  }
}
