import { useCallback, useEffect, useState } from 'react'

import { CookieConsent } from './types'
import {
  getCookieConsent,
  setCookieConsent,
  COOKIE_UPDATE_EVENT,
} from './utils'

export const useConsent = () => {
  const [value, setValue] = useState<CookieConsent | null | undefined>(
    getCookieConsent(),
  )
  const updateCookieConsent = useCallback(
    (values: CookieConsent) => {
      setCookieConsent(values)
      document.dispatchEvent(
        new CustomEvent(COOKIE_UPDATE_EVENT, { detail: values }),
      )
    },
    [setCookieConsent, setValue],
  )

  useEffect(() => {
    const fn = ({ detail }: CustomEvent<CookieConsent>) => {
      setValue(detail)
    }
    document.addEventListener(COOKIE_UPDATE_EVENT, fn as any)
    return () => {
      document.removeEventListener(COOKIE_UPDATE_EVENT, fn as any)
    }
  }, [])

  return {
    consent: value,
    updateCookieConsent,
  }
}
