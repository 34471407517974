import { ContextState } from './types'

export const selectUser = ({ user }: ContextState) => {
  return user
}

export const selectMode = ({ mode }: ContextState) => {
  return mode
}

export const selectTimezone = ({ timezone }: ContextState) => {
  return timezone
}
