import styled, { css } from 'styled-components'

import { pxToRem } from '../../lib/utils'
import { Anchor } from '../../Anchor'

export const Wrap = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color.grey['300']};
    color: ${theme.color.grey['700']};
    font-family: ${theme.font.poppins};
    font-size: ${pxToRem(16)};
    text-align: center;
    z-index: 9000;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    padding: ${pxToRem(20)};
  `,
)

export const ControlWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${pxToRem(4)};
  width: 100%;

  > * {
    margin-right: ${pxToRem(10)};
  }

  > *:last-of-type {
    margin-right: 0;
  }
`

export const SInlineLink = styled(Anchor)`
  font-size: ${pxToRem(16)};

  :first-of-type {
    margin-right: ${pxToRem(10)};
  }
`
