import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import { Transition } from '@headlessui/react'
import { Button } from '@style-space/components/src/Button'
import { XIcon as X } from '@style-space/components/src/icons/XIcon'

import { pxToRem } from '../../utils/utils'
import { useToast } from '../../context/ToastContext/useToast'

const Wrap = styled.div(
  ({ theme }) => css`
    width: 100%;
    position: fixed;
    transition: 150ms top;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: ${pxToRem(46)};
    background-color: ${theme.color.tertiary['800']};
    font-size: ${pxToRem(14)};
    line-height: 1;
    padding: ${pxToRem(4, 65, 4, 20)};
    color: ${theme.color.white};
    text-align: center;
    top: ${pxToRem(88)};
    z-index: 999;

    &.enter {
      z-index: 999;
      transition: 150ms top;
    }

    &.enter-from {
      top: ${pxToRem(88 - 46)};
    }

    &.enter-to {
      top: ${pxToRem(88)};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      top: ${pxToRem(75)};

      &.enter-from {
        top: ${pxToRem(75 - 46)};
      }

      &.enter-to {
        top: ${pxToRem(75)};
      }
    }
  `,
)

export const XIcon = styled(X)(
  ({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${pxToRem(9)} !important;
    height: ${pxToRem(9)} !important;
    path {
      fill: ${theme.color.white};
    }
  `,
)

const SLightButton = styled(Button)(
  ({ theme }) => css`
    && {
      margin-left: ${pxToRem(16)};
      color: ${theme.color.white};
      width: max-content;
      border-color: ${theme.color.white};
      background: transparent;
      white-space: nowrap;

      &.close-button {
        width: ${pxToRem(42)};
        height: ${pxToRem(42)};
        border-color: transparent;
        position: absolute;
        right: ${pxToRem(13)};
      }

      :hover {
        border-color: ${theme.color.gray};
        color: ${theme.color.white};
        background-color: transparent;
      }
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      .hide {
        display: none;
      }
    }
  `,
)

type Props = {
  text: string
  button?: string | JSX.Element
  onClose?(): void
  callback?(): void
}

export const RawInfoToast: React.FC<Props> = ({
  text,
  button,
  onClose,
  callback,
}) => {
  const show = Boolean(text)
  const showClass = Boolean(text) ? 'show' : ''
  return (
    <Transition
      as={Fragment}
      show={show}
      enter="enter"
      enterFrom="enter-from"
      enterTo="enter-to"
      leave="enter"
      leaveFrom="enter-to"
      leaveTo="enter-from"
    >
      <Wrap className={showClass} data-testid="info-toast">
        {text}
        {onClose && text && (
          <SLightButton
            className="close-button"
            buttonType="secondary"
            buttonSize="small"
            data-testid="info-toast-button"
            onClick={onClose}
            iconSvg={<XIcon />}
          />
        )}
        {callback && (
          <SLightButton
            buttonType="secondary"
            buttonSize="small"
            data-testid="info-toast-button"
            onClick={callback}
          >
            {button}
          </SLightButton>
        )}
      </Wrap>
    </Transition>
  )
}

export const InfoToast: React.FC = () => {
  const {
    data: { text, button, persistent, callback },
    blockToast,
  } = useToast()

  return (
    <RawInfoToast
      button={button}
      text={text}
      onClose={persistent ? undefined : blockToast}
      callback={callback}
    />
  )
}
