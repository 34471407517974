import { formatISO } from './format'
import { DateData, DurationUnitType } from './types'
import { DayJs } from './constants'
import { shiftDate, shiftDateToBrowser } from './timezone'

export const getDate = (date: DateData = DayJs()) => DayJs(date)
export const getTime = (date?: DateData) => getDate(date).valueOf()

export const isPast = (date: DateData) => {
  return getTime(date) < getTime()
}

export const isLessThan150YearsOld = (dateOfBirth: DateData) => {
  if (!dateOfBirth || !isPast(dateOfBirth)) {
    return null
  }
  const diff = getTime() - getTime(dateOfBirth)
  return DayJs.duration(diff).asYears() < 150
}

export const isAtLeast18YearsOld = (dateOfBirth: DateData) => {
  if (!dateOfBirth || !isPast(dateOfBirth)) {
    return null
  }

  const diff = getTime() - getTime(dateOfBirth)
  return DayJs.duration(diff).asYears() >= 18
}

export const getMonthRange = (date: DateData) => {
  const start = shiftDate(shiftDateToBrowser(date).startOf('month'))
  const end = shiftDate(shiftDateToBrowser(date).endOf('month'))
  return [formatISO(start), formatISO(end)]
}

export const getDayStart = (rawDate: DateData) => {
  return shiftDate(shiftDateToBrowser(rawDate).startOf('day'))
}

export const getDayEnd = (rawDate: DateData) => {
  return shiftDate(shiftDateToBrowser(rawDate).endOf('day'))
}

export const getDayTime = (rawDate: DateData, format: DurationUnitType) => {
  const dayStartTime = getTime(getDayStart(rawDate))
  const dateTime = getTime(rawDate)
  return DayJs.duration(dateTime - dayStartTime).as(format)
}

export const dateRangeToIso = (
  fromDate: number | string | Date,
  toDate: number | string | Date,
): [string, string] => {
  return [formatISO(fromDate), formatISO(toDate)]
}
