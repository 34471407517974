import React, { useContext } from 'react'
import { Form, Formik } from 'formik'
import { boolean, object } from 'yup'
import { ThemeContext } from 'styled-components'

import {
  SModal,
  Text,
  SInlineLink,
  SSlideCheckbox,
  ControlWrap,
} from './CookieModal.style'
import { LEGAL_DOCUMENTS_ROUTES } from '../../lib/utils'
import { DefaultProps } from '../../types'
import { DefaultTheme } from '../../themes'
import { Button } from '../../Button'

const SCHEMA = object().shape({
  functional: boolean(),
  analytics: boolean(),
})

export const COOKIE_VALUES = {
  functional: true,
  analytics: false,
}

export type CookieValues = typeof COOKIE_VALUES

type Props = DefaultProps & {
  isOpen: boolean
  onSubmit(values: CookieValues): void
  onClose(): void
}

export const CookieModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  className = '',
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <SModal
      modalName="Cookie consent modal"
      theme={theme}
      title="Personalization settings & cookies"
      isOpen={isOpen}
      onClose={onClose}
      className={className}
    >
      <Text theme={theme}>
        We use cookies (including third party cookies) to provide you with a
        better user experience by being more responsive and personalized, such
        as enhancing website navigation, analyzing website usage and to enhance
        the user experience and assisting in our marketing efforts. By clicking
        ‘Accept all’ you agree to the use of such cookies.
        <br />
        <br />
        For more information on the use of cookies on this website please read
        our cookie policy located at the bottom of our landing page at
        www.stylespace.com. By using this website and/or the services, you agree
        to the Privacy Notice located at the bottom of our landing page at
        www.stylespace.com.
        <br />
        <SInlineLink
          anchorType="inline"
          underline
          theme={theme}
          data-testid="cookie-modal-cookie-policy"
          target="_blank"
          href={LEGAL_DOCUMENTS_ROUTES.cookiePolicy}
        >
          Cookie policy
        </SInlineLink>
        <SInlineLink
          anchorType="inline"
          underline
          theme={theme}
          data-testid="cookie-modal-privacy-policy"
          target="_blank"
          href={LEGAL_DOCUMENTS_ROUTES.privacyPolicy}
        >
          Privacy policy
        </SInlineLink>
      </Text>
      <Formik
        initialValues={{ ...COOKIE_VALUES }}
        validationSchema={SCHEMA}
        validateOnBlur
        onSubmit={onSubmit}
      >
        <Form>
          <SSlideCheckbox
            theme={theme}
            data-testid="cookie-modal-functional-input"
            disabled
            id="functional"
            label="Functional"
            name={'functional'}
          />
          <SSlideCheckbox
            theme={theme}
            data-testid="cookie-modal-analytics-input"
            id="analytics"
            label="Analytics"
            name={'analytics'}
          />
          <ControlWrap>
            <Button
              buttonType="secondary"
              buttonSize="small"
              data-testid="cookie-modal-confirm"
              type="submit"
            >
              CONFIRM
            </Button>
            <Button
              buttonType="primary"
              buttonSize="small"
              type="button"
              data-testid="cookie-modal-accept-all"
              onClick={() => {
                onSubmit(
                  Object.keys(COOKIE_VALUES).reduce(
                    (res, key) => ({ ...res, [key]: true }),
                    {} as any as CookieValues,
                  ),
                )
              }}
            >
              ACCEPT ALL
            </Button>
          </ControlWrap>
        </Form>
      </Formik>
    </SModal>
  )
}
CookieModal.displayName = 'CookieModal'
