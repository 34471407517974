import { pxToRem } from '../lib/utils'

export const blue = '#1D5486'

export const theme = {
  font: {
    mukta: 'Mukta, serif',
    poppins: 'Poppins, serif',
    weight: {
      bold: '700',
      semibold: '600',
      medium: '500',
      regular: '400',
      light: '200',
    },
  },
  color: {
    primary: {
      900: '#0D3748',
      800: '#12475C',
      700: '#19556D',
      600: '#20627D',
      500: '#286F8C',
      400: '#3D8AAA',
      300: '#5EA8C7',
      200: '#86C5DF',
      100: '#BBE1F1',
      50: '#E8F4F9',
    },
    secondary: {
      900: '#864D39',
      800: '#9D5E48',
      700: '#B5725B',
      600: '#CC876F',
      500: '#D9977F',
      400: '#EAAC95',
      300: '#F9C3AF',
      200: '#FCD2C2',
      100: '#FFE0D5',
      50: '#FFF0EB',
    },
    tertiary: {
      900: '#504EA0',
      800: '#6664B7',
      700: '#7876CA',
      600: '#8B89DA',
      500: '#9D9BE9',
      400: '#B7B5F5',
      300: '#C7C6F9',
      200: '#D6D5F9',
      100: '#E4E3FF',
      50: '#F0F0FC',
    },
    grey: {
      900: '#18181B',
      800: '#27272A',
      700: '#3F3F46',
      600: '#52525B',
      500: '#71717A',
      400: '#A1A1AA',
      300: '#D4D4D8',
      200: '#E4E4E7',
      100: '#F4F4F5',
      50: '#FAFAFA',
    },
    red: {
      900: '#7F1D1D',
      800: '#991B1B',
      700: '#B91C1C',
      600: '#DC2626',
      500: '#EF4444',
      400: '#F87171',
      300: '#FCA5A5',
      200: '#FECACA',
      100: '#FEE2E2',
      50: '#FEF2F2',
    },

    darkOpa: 'rgba(0, 0, 0, 0.35)',
    white: '#ffffff',

    // deprecated
    blue,
    lightGreen: '',
    lightBlue: '',
    darkBlue: '',
    dark: '',
    medium: '',
    gray: '',
    gray2: '',
    light: '',
    superLightGray: '',
    lighter: '',
    highlight: '',
    orange: '',
    lightOrange: '',
    lighterOrange: '',
    yellow: '',
  },

  radius: {
    super: pxToRem(32),
    small: pxToRem(4),
    medium: pxToRem(8),
    big: pxToRem(10),
  },
  boxShadow: {
    default: '0 1.5rem 3.125rem rgba(0, 0, 0, 0.1)',
    focusBoxShadow: `${pxToRem(0, 0, 0, 2)} #ffffff,
    ${pxToRem(0, 0, 0, 4)} #286F8C`,
  },
  textShadow: {},
  breakpoint: {
    tabletV: pxToRem(640),
    tabletH: pxToRem(960),
    laptop: pxToRem(1280),
    desktop: pxToRem(1600),
  },
  maxWidth: pxToRem(1100),
  maxScreen: pxToRem(1280),
}

// deprecated
theme.color.dark = theme.color.grey['800']
theme.color.blue = theme.color.primary['500']
theme.color.lightBlue = theme.color.primary['400']
theme.color.gray = theme.color.grey['300']
theme.color.medium = theme.color.grey['500']
theme.color.lighter = theme.color.grey['200']
theme.color.light = theme.color.grey['300']
theme.color.orange = theme.color.secondary['400']
theme.color.lightGreen = '#2EAF00'
theme.color.darkBlue = '#1A4B78'
theme.color.gray2 = '#c4c4c4'
theme.color.superLightGray = '#EFEFEF'
theme.color.highlight = '#E9B09B'
theme.color.lightOrange = '#e8c6b9'
theme.color.lighterOrange = '#F4D7CC'
theme.color.yellow = '#fff9d5'
