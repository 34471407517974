import { DateData } from './types'
import { DayJs, HOUR } from './constants'

export const getTimezoneOffset = (timezone?: string, date?: DateData) => {
  return DayJs(date).tz(timezone).utcOffset()
}

export const getOffset = (date?: DateData) => {
  return DayJs(date).tz().utcOffset()
}

export const getBrowserOffset = (date?: DateData) => {
  return -1 * new Date(DayJs(date).valueOf()).getTimezoneOffset()
}

export const isSupportedTimezone = (timezone: string) => {
  try {
    return !Object.is(getTimezoneOffset(timezone), -0)
  } catch (e) {
    console.error(e)
    return false
  }
}

export const getBrowserTimezone = (
  raw: boolean = false,
  guessedTimezone: string = DayJs.tz.guess(),
) => {
  let timezone = isSupportedTimezone(guessedTimezone)
    ? guessedTimezone
    : 'Europe/Berlin'
  const timezoneValue = timezone.replace('/', '')
  return raw ? timezone : timezoneValue
}

let _timezone = getBrowserTimezone(true)
export const setTimezone = (timezone: string) => {
  DayJs.tz.setDefault(timezone)
  _timezone = timezone
}

export const getTimezone = () => {
  return _timezone
}

export const getGMTString = (timezone?: string, date?: DateData) => {
  const hourOffset = getTimezoneOffset(timezone, date) / HOUR
  return `GMT${hourOffset >= 0 ? `+${hourOffset}` : hourOffset}`
}

export const shiftDate = (
  date: DateData,
  offset: number = getOffset(date),
  originOffset: number = getBrowserOffset(date),
) => {
  const dateValue = DayJs(date).valueOf()
  const diff = DayJs.duration(
    Math.abs(offset - originOffset),
    'minutes',
  ).asMilliseconds()
  const ret = originOffset > offset ? dateValue + diff : dateValue - diff
  return DayJs(ret)
}

export const shiftDateToBrowser = (date: DateData) =>
  shiftDate(date, getBrowserOffset(date), getOffset(date))
