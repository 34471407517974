export const QUERY = `
  query User {
    user: whoami {
        id
        email
        userProfile {
            fullName
            avatar
            gender
            timezone(format: ENGLISH)
        }
        capabilities {
            maxSessions
            canManageSessions
            canApproveRequests
            canCheckStyleSubmissionStatus
        }
    }
  }
`
