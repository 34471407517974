import Cookies from 'js-cookie'

import { ENV } from '../../lib/utils/constants'
import { getDomain } from '../../lib/utils'
import { AppMode } from './types'

const PRESERVE_KEY = `authContext-${ENV}`

type PreservedData = {
  mode: AppMode | null
}

export const storeData = ({ mode }: PreservedData) => {
  Cookies.set(PRESERVE_KEY, JSON.stringify({ mode }), {
    domain: getDomain(),
    expires: 365,
  })
}

export const removeData = () => {
  Cookies.remove(PRESERVE_KEY)
}

export const getData = (): PreservedData | null => {
  if (typeof window === 'undefined') {
    return null
  }
  const stringData = Cookies.get(PRESERVE_KEY)
  if (!stringData) {
    return {
      mode: null,
    }
  }
  try {
    return JSON.parse(stringData)
  } catch (e: any) {
    removeData()
    throw new Error(e)
  }
}
