import styled, { css } from 'styled-components'

import { pxToRem } from '../lib/utils'
import { Theme } from '../themes'
import { NextLink } from '../lib/router'

const Styled = ({ theme }: { theme: Theme }) => css`
  color: ${theme.color.grey['700']};
  font-size: ${pxToRem(14)};
  font-weight: ${theme.font.weight.regular};
  border: ${pxToRem(1)} dotted transparent;
  border-radius: ${theme.radius.small};
  padding: 0.25rem 0.3125rem;
  text-decoration: none;

  :hover {
    border-color: ${theme.color.grey['700']};
  }
  :focus {
    outline: none;
  }
  :focus-visible {
    border-color: ${theme.color.grey['700']};
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.inline {
    display: inline;
    margin: 0;
    padding: 0;
    border: ${pxToRem(1)} dotted transparent;
    white-space: nowrap;
    color: ${theme.color.primary['500']};
    text-decoration: none;
    font-weight: inherit;
    font-size: inherit;
    :hover {
      text-decoration: underline;
    }
    :focus-visible {
      border: ${pxToRem(1)} dotted ${theme.color.grey['700']};
    }
    &.underline {
      text-decoration: underline;
    }
    &.bold {
      font-weight: ${theme.font.weight.bold};
    }
  }

  &.button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: ${pxToRem(11, 23)};
    font-size: ${pxToRem(16)};
    max-width: max-content;
    line-height: 1.5;
    box-shadow: none;
    text-align: center;
    text-decoration: none;
    font-weight: ${theme.font.weight.bold};
    cursor: pointer;
    border: ${pxToRem(1)} dotted transparent;
    border-radius: ${theme.radius.small};

    :hover {
      border-color: ${theme.color.grey['700']};
    }
    :focus-visible {
      border-color: ${theme.color.grey['700']};
    }
    :focus {
      outline: none;
    }
  }

  &.primary {
    color: ${theme.color.white};
    background-color: ${theme.color.primary['500']};
    :hover {
      background-color: ${theme.color.primary['400']};
      border: ${pxToRem(1)} solid ${theme.color.primary['400']};
    }
    :focus-visible {
      border: ${pxToRem(1)} solid ${theme.color.primary['500']};
      background-color: ${theme.color.primary['500']};
      box-shadow: ${theme.boxShadow.focusBoxShadow};
      :hover {
        border: ${pxToRem(1)} solid ${theme.color.primary['400']};
        background-color: ${theme.color.primary['400']};
      }
    }
  }

  &.secondary {
    color: ${theme.color.grey['500']};
    background-color: ${theme.color.white};
    border: ${pxToRem(1)} solid ${theme.color.grey['300']};
    :hover {
      color: ${theme.color.grey['500']};
      background-color: ${theme.color.white};
      border: ${pxToRem(1)} solid ${theme.color.grey['400']};
    }
    :focus-visible {
      border: ${pxToRem(1)} solid ${theme.color.grey['300']};
      box-shadow: ${theme.boxShadow.focusBoxShadow};
      :hover {
        border: ${pxToRem(1)} solid ${theme.color.grey['400']};
      }
    }
  }

  &.regular {
    padding: ${pxToRem(11, 23)};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
  }
  &.small {
    padding: ${pxToRem(7, 15)};
    font-size: ${pxToRem(14)};
    line-height: 1.43;
  }
`

export const A = styled.a(Styled)
export const SNextLink = styled(NextLink)(Styled)
