import 'cross-fetch/polyfill'

import { Category, Gender, Options, RawFilterTags } from '../../@types/types'
import { getDate } from '../lib/time'

export const convertRemToPixels = (rem: number) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export const encodeBlob = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64String = reader.result as string
        resolve(base64String)
      }
    } catch (e) {
      reject(e)
      return ''
    }
  })
}

export const compareOutOfOrderArrays = (arrOne: string[], arrTwo: string[]) => {
  return (
    arrOne.length === arrTwo.length &&
    arrOne.every(function (element) {
      return arrTwo.includes(element)
    })
  )
}

export const preloadImage = (url: string, callback: () => void) => {
  const img = new Image()
  img.src = url
  img.onload = callback
}

export const trimStringArray = (value: any) => {
  if (!Array.isArray(value)) {
    return value
  }
  const nonEmptyArray = value.filter((item) => item)
  if (nonEmptyArray.some((item) => typeof item !== 'string')) {
    return value
  }

  return nonEmptyArray.filter((item) => Boolean(item.trim()))
}

export const getFirstName = (fullName: string) => {
  const [firstName] = fullName.split(' ')
  return firstName
}

export const toLeadingUppercase = (word: string) => {
  if (!word) {
    return word
  }
  const firstLetter = word[0]
  const rest = word.slice(1, word.length)
  return `${firstLetter.toUpperCase()}${rest.toLowerCase()}`
}

export const getNameInitials = (fullName: string) => {
  if (!fullName) {
    return null
  }
  const [firstName, ...lastNames] = fullName.split(' ')
  if (!lastNames.length) {
    return firstName[0].toLocaleUpperCase()
  }

  return `${firstName[0].toLocaleUpperCase()}${lastNames[
    lastNames.length - 1
  ][0].toLocaleUpperCase()}`
}

export const isEmptyValue = (value: any) =>
  !Boolean(value || value === 0) ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === 'object' && Object.keys(value).length === 0)

export const arrayToObject = (value: any[]) => Object.assign({}, value)

export const pxToRem = (...amounts: number[]) =>
  amounts.map((amount) => `${amount / 16}${amount ? 'rem' : ''}`).join(' ')

export const toStringArray = (object: { [key: string]: boolean }) =>
  Object.entries(object).reduce(
    (res, [key, value]) => [...res, ...(value ? [key] : [])],
    [] as string[],
  )

export const deSlugify = (value: string) => value.split('-').join(' ')
export const slugify = (value: string | number) => {
  if (typeof value === 'string') {
    return value.split(' ').join('-')
  }
  return value
}

export const getWidthSrc = (image: string, size: number) => {
  const [path, format] = image.split('.')
  return `${path}-${size}.${format}`
}

export const getRadianAngle = (degreeValue: number) => {
  return (degreeValue * Math.PI) / 180
}

export const filterEmptyArrayEnd = (
  array: any[],
  callback: (val: any) => boolean = (val: any) => Boolean(val),
) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const valueToCheck = array[i]
    if (callback(valueToCheck) || valueToCheck === 0) {
      return array.slice(0, i + 1)
    }
  }
  return []
}

export const isBookingCompleted = (when: string, durationInMinutes: number) => {
  const now = Date.now()
  const end = getDate(when).add(durationInMinutes, 'minute').valueOf()
  return now >= end
}

export const removeDuplicatedOptions = (options: Options) => {
  const optionKeysObject: { [key: string]: boolean } = {}
  return options.reduce((ret, { label, value }) => {
    if (!optionKeysObject[`${label}-${value}`]) {
      optionKeysObject[`${label}-${value}`] = true
      return [...ret, { label, value }]
    }
    return ret
  }, [] as Options)
}

export const arrayDiff = (arr1: string[], arr2: string[]) => {
  const array1Map = arr1.reduce(
    (res, item) => {
      return {
        ...res,
        [item]: (res[item] || 0) + 1,
      }
    },
    {} as { [key: string]: number },
  )

  const array2Map = arr2.reduce(
    (res, item) => {
      return {
        ...res,
        [item]: (res[item] || 0) + 1,
      }
    },
    {} as { [key: string]: number },
  )

  const ret: string[] = []

  Object.entries(array1Map).forEach(([key, value]) => {
    const array2Amount = array2Map[key] || 0
    const amount = array2Amount ? value - array2Amount : value
    new Array(amount).fill(null).forEach(() => {
      ret.push(key)
    })
  })
  return ret
}

export const getTagsAsOptions = (
  tagsData: RawFilterTags,
  categoryToFilter: Category,
  genderToFilter: Gender,
): Options<string, string> => {
  const ret: RawFilterTags = tagsData.filter(
    ({ category, gender }) =>
      (category === categoryToFilter ||
        category === 'ANY' ||
        categoryToFilter === 'ANY') &&
      (gender === genderToFilter ||
        gender === 'ANY' ||
        genderToFilter === 'ANY'),
  )
  const countMap = ret.reduce(
    (ret, { name }) => {
      return {
        ...ret,
        [name]: name,
      }
    },
    {} as { [key: string]: string },
  )
  return Object.entries(countMap).map(([label, value]) => ({ label, value }))
}
