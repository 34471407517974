import { AuthAction, ContextState } from './types'
import { ArgumentError } from '../../lib/error'

export function authReducer(
  state: ContextState,
  action: AuthAction,
): ContextState {
  switch (action.type) {
    case 'updateUser':
      return { ...state, user: action.payload }
    case 'updateMode':
      return { ...state, mode: action.payload }
    case 'updateTimezone':
      return { ...state, timezone: action.payload }
    default:
      throw new ArgumentError('AuthReducer', 'Unsupported action type')
  }
}
