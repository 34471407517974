import { useContext } from 'react'

import { ToastContext } from './ToastContext'

export const useToast = () => {
  const {
    text,
    persistent,
    button,
    callback,
    showToast,
    hideToast,
    blockToast,
  } = useContext(ToastContext)
  return {
    data: {
      text,
      persistent,
      button,
      callback,
    },
    showToast,
    hideToast,
    blockToast,
  }
}
