import React, { HTMLAttributes, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Header, Line } from './Heading.style'
import { HeaderStrength } from '../types'
import { DefaultTheme } from '../themes'

type Props = HTMLAttributes<HTMLHeadingElement> & {
  className?: string
  headingType: 'style1' | 'style2' | 'style3' | 'style4' | 'style5' | 'style6'
  type: HeaderStrength
  line?: boolean
  children: React.ReactNode
}

export const Heading = React.forwardRef<HTMLHeadingElement, Props>(
  (
    {
      className = '',
      headingType = 'style1',
      type,
      line = false,
      children,
      ...rest
    },
    ref,
  ) => {
    const theme = useContext(ThemeContext) || DefaultTheme
    const lineClass = line ? 'line ' : ''
    return (
      <Header
        theme={theme}
        ref={ref}
        className={`${lineClass} ${className} ${headingType}`}
        as={type}
        data-testid="heading"
        {...rest}
      >
        {line && <Line theme={theme} data-testid="heading-ine" />}
        {children}
      </Header>
    )
  },
)
Heading.displayName = 'Title'
