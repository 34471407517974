/**
 * Filter empty items from an array
 * ['1', '2', ' ', '3'] => ['1', '2', '3']
 * @param value Array of strings
 */
export const filterEmptyStrings = (value: string[]) => {
  if (!Array.isArray(value)) {
    return value
  }
  const nonEmptyArray = value.filter((item) => item)
  if (nonEmptyArray.some((item) => typeof item !== 'string')) {
    return value
  }

  return nonEmptyArray.filter((item) => Boolean(item.trim()))
}

/**
 * Get initials from full name
 * Peter Sreid => PS
 * Peter => P
 * Peter Middle Name => PN
 * @param fullName Name to get initials from
 * @returns Initials of given name
 */
export const getNameInitials = (fullName: string) => {
  if (!fullName) {
    return null
  }
  const [firstName, ...lastNames] = fullName.split(' ')
  if (!lastNames.length) {
    return firstName[0].toLocaleUpperCase()
  }

  return `${firstName[0].toLocaleUpperCase()}${lastNames[
    lastNames.length - 1
  ][0].toLocaleUpperCase()}`
}

/**
 * Cap text with ellipsis
 * @param text Text to cap
 * @param amount amount to cut it
 * @returns Text with up to amount characters with ...
 */
export const capTextWithEllipsis = (text: string, amount: number) => {
  const ellipsis = text.length >= amount
  return `${text.slice(0, amount)}${ellipsis ? '...' : ''}`
}
