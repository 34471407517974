import { NextPage } from 'next'
import React from 'react'
import Script from 'next/script'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { AuthProvider } from '@style-space/components/src/Context/AuthContext/AuthContext'
import { GraphqlProvider } from '@style-space/components/src/lib/graphql/GraphqlProvider'
import { CookiePolicy } from '@style-space/components/src/CookiePolicy'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-date-picker/dist/DatePicker.css'
import 'video.js/dist/video-js.css'
import dynamic from 'next/dynamic'

import { theme } from '../themes'
import { Footer } from '../components/Footer'
import { NPage } from '../../@types/types'
import { MODAL_CLASS } from '../utils/constants'
import { ToastProvider } from '../context/ToastContext/ToastContext'

const TopPanel = dynamic((): any =>
  import('../components/TopPanel').then((mod) => mod.TopPanel),
)

const GlobalStyle = createGlobalStyle`
    * {
      font-family: Mukta, sans-serif;
      font-style: normal;
      box-sizing: border-box;
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }
    
    input {
      -webkit-appearance: none;
    }
    
    html {
      height: 100vh;
    }
   
    body {
      margin: 0;
      position: relative;
      width: 100%;
      background-color: #ffffff;
      
      &.${MODAL_CLASS} {
        position: fixed;
        left: 0;
        right: 0;
        overflow: hidden;
      }
    }
  `

type Props = {
  pageProps: any
  Component: NPage
}

const MyApp: NextPage<Props> = ({ Component, pageProps }) => {
  return (
    <>
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="https://js.hs-scripts.com/24320551.js"
      />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <GraphqlProvider state={pageProps.dehydratedState}>
          <AuthProvider>
            <ToastProvider component={Component as any}>
              {!Component.customTopPanel && <TopPanel />}
              <Component {...pageProps} />
              {!Component.customFooter && <Footer />}
              <CookiePolicy />
            </ToastProvider>
          </AuthProvider>
        </GraphqlProvider>
      </ThemeProvider>
    </>
  )
}
MyApp.displayName = '_app'

export default MyApp
