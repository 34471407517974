import React, { PropsWithChildren, useRef } from 'react'
import { useConsent } from '@style-space/components/src/lib/consent'

import { InfoToast } from '../../components/common/InfoToast'

export const TOAST_CLASS = 'info-toast'
const PRESERVE_CONTEXT_KEY = 'info_toast_preserve'

type BlockedData = { [key: string]: boolean }

type ContextData = {
  key: string
  text: string
  button?: string | JSX.Element
  persistent?: boolean
  callback?: () => void
}

type ContextControllers = {
  showToast: (data: ContextData) => void
  hideToast: () => void
  blockToast: () => void
}

export const getPreservedState = (): BlockedData => {
  if (process.browser) {
    const data = window.localStorage.getItem(PRESERVE_CONTEXT_KEY)
    if (!data) {
      return {}
    }
    return JSON.parse(data)
  }
  return {}
}

export const ToastContext = React.createContext<
  ContextData & ContextControllers
>(null as any)

type Props = PropsWithChildren<{
  component: React.Component
}>

export const ToastProvider: React.FC<Props> = ({ component, children }) => {
  const { consent } = useConsent()
  const initLock = useRef(true)
  const [blocked, setBlocked] = React.useState({
    ...getPreservedState(),
  })
  const [state, setState] = React.useState<ContextData>({
    text: '',
    key: '',
    button: '',
  })

  const showToast = React.useCallback(
    (data: ContextData) => {
      if (blocked[data.key]) {
        return
      }
      setState(data)
    },
    [setState, blocked],
  )

  const hideToast = React.useCallback(() => {
    setState({ text: '', key: '', button: '' })
  }, [setState])

  const blockToast = React.useCallback(() => {
    const key = state.key
    if (state.key) {
      setBlocked((prev) => ({ ...prev, [key]: true }))
    }
    hideToast()
  }, [hideToast, setBlocked, state.key])

  const value = React.useMemo(() => {
    return {
      ...state,
      showToast,
      hideToast,
      blockToast,
    }
  }, [state, showToast, hideToast, blockToast])

  React.useEffect(() => {
    if (initLock.current) {
      initLock.current = false
      return
    }
    showToast({ key: '', text: '', button: '' })
  }, [component])

  React.useEffect(() => {
    if (Boolean(state.text)) {
      document.body.classList.add(TOAST_CLASS)

      return () => {
        document.body.classList.remove(TOAST_CLASS)
      }
    }
  }, [state.text])

  React.useEffect(() => {
    if (!consent?.functional) {
      return
    }
    window.localStorage.setItem(PRESERVE_CONTEXT_KEY, JSON.stringify(blocked))
  }, [blocked, consent])

  return (
    <ToastContext.Provider value={value}>
      <InfoToast />
      {children}
    </ToastContext.Provider>
  )
}
