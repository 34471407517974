import styled, { css } from 'styled-components'

import { pxToRem } from '../../lib/utils'
import { Modal } from '../../Modal'
import { Anchor } from '../../Anchor'
import { SlideCheckbox } from '../../Form/SlideCheckbox'

export const SModal = styled(Modal)(
  ({ theme }) => css`
    .headless-modal-inner-wrap {
      padding: ${pxToRem(32, 24, 16)};
      max-width: ${pxToRem(375)};

      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        max-width: none;
        padding: ${pxToRem(32, 24)};
      }

      .headless-modal-title {
        width: 100%;
        margin-bottom: ${pxToRem(18)};
        padding-right: ${pxToRem(48)};
      }
    }
  `,
)

export const Text = styled.p(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.color.grey['700']};
    line-height: 1.3;
    font-size: ${pxToRem(16)};
    margin: 0 0 ${pxToRem(32)};
  `,
)

export const SSlideCheckbox = styled(SlideCheckbox)(
  ({ theme }) => css`
    margin-bottom: ${pxToRem(20)};
    label {
      font-weight: ${theme.font.weight.regular};
      font-size: ${pxToRem(16)};
      line-height: 1.3;
    }
  `,
)

export const SInlineLink = styled(Anchor)`
  font-size: ${pxToRem(16)};

  :first-of-type {
    margin-right: ${pxToRem(10)};
  }
`

export const ControlWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${pxToRem(40)};
  width: 100%;

  > * {
    margin-right: ${pxToRem(10)};
  }

  > *:last-of-type {
    margin-right: 0;
  }
`
