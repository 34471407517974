import mixpanel from 'mixpanel-browser'

import { IS_DEV, MIXPANEL_TOKEN } from '../utils/constants'

let initialized = false

export const initMixpanel = () => {
  if (initialized) {
    return
  }
  const token = MIXPANEL_TOKEN
  if (!token) {
    console.error('Mixpanel token is not set')
    return
  }
  mixpanel.init(token, { debug: IS_DEV })
  initialized = true
}

export const track = (event: string, props: Record<string, string>) => {
  if (!initialized) {
    return
  }
  mixpanel.track(event, props)
}

export const identify = (id: string) => {
  if (!initialized) {
    return
  }
  if (!id) {
    throw new Error(`Identify needs a valid id, got: "${id}"`)
  }
  mixpanel.identify(id)
}

export const reset = () => {
  if (!initialized) {
    return
  }
  mixpanel.reset()
}
