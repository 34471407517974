import React from 'react'

import { useConsent } from '../lib/consent'
import { CookieInfo } from './CookieInfo'
import { COOKIE_VALUES, CookieModal, CookieValues } from './CookieModal'

type Props = {}

export const CookiePolicy: React.FC<Props> = ({}) => {
  const { consent, updateCookieConsent: setConsent } = useConsent()
  const [moreInfo, setMoreInfo] = React.useState(false)

  const onSubmit = React.useCallback(
    (values: CookieValues) => {
      setConsent(values)
    },
    [setConsent],
  )

  if (consent) {
    return null
  }

  if (consent === undefined) {
    return null
  }

  return (
    <>
      <CookieModal
        isOpen={moreInfo}
        onClose={() => setMoreInfo(false)}
        onSubmit={onSubmit}
      />
      <CookieInfo
        onMore={() => setMoreInfo(true)}
        onAgree={() => {
          onSubmit(
            Object.keys(COOKIE_VALUES).reduce(
              (res, key) => ({ ...res, [key]: true }),
              {} as any as CookieValues,
            ),
          )
        }}
      />
    </>
  )
}

CookiePolicy.displayName = 'CookiePolicy'
