import styled, { css } from 'styled-components'

import { Heading } from '../Heading'
import { XIcon } from '../icons/XIcon'
import { Button } from '../Button'
import { pxToRem } from '../lib/utils'

export const SHeading: typeof Heading = styled(Heading)`
  font-size: ${pxToRem(28)};
  margin: 0 0 2.125rem;
` as any

export const XButton = styled(Button)`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  cursor: pointer;
  top: 2.25rem;
  right: 2.25rem;
  z-index: 1;
`

export const DialogWrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: auto;
  z-index: 9000;
`

export const DialogInner = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    max-width: 31.375rem;
    height: max-content;
    margin: auto auto;
    flex: none;
    padding: 7rem 5.5rem 3.3125rem;
    background-color: ${theme.color.white};

    .defaultAnim & {
      &.enter {
        transition:
          100ms transform ease-in,
          100ms opacity ease-in;
      }
      &.enterFrom {
        transform: scale(0.85);
        opacity: 0;
      }
      &.enterTo {
        transform: scale(1);
        opacity: 1;
      }
    }

    .bottomupAnim & {
      &.enter {
        position: absolute;
        transition:
          150ms top ease-in,
          150ms opacity ease-in;
      }
      &.enterFrom {
        top: 100vh;
        opacity: 0;
      }
      &.enterTo {
        top: 0;
        opacity: 1;
      }
    }
    :focus {
      outline: none;
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      min-height: 100%;
      width: 100%;
      margin: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      max-width: 100%;
    }
  `,
)

export const SOverlay = styled.div(
  ({ theme }) => css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.color.darkOpa};

    .defaultAnim & {
      &.enter {
        transition: 100ms opacity ease-in;
      }
      &.enterFrom {
        opacity: 0;
      }
      &.enterTo {
        opacity: 1;
      }
    }
  `,
)

export const SXIcon = styled(XIcon)`
  width: 0.875rem;
  height: 0.875rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
