import * as Sentry from '@sentry/nextjs'

import { GraphqlResponseError } from '../error/GraphQlError'
import { getToken, refreshToken, removeToken } from './token'

export const TOKEN_EXPIRED_ERROR = 'Token Expired'
export const TOKEN_WRONG_ERROR = 'Invalid Bearer Token - Invalid Token'
export const TOKEN_NOT_FOUND = 'Invalid Bearer Token - Not Found'
export const PERMISSION_ERROR = 'Permission Denied'
export const UNEXPECTED_ERROR = 'Graphql fetch error'
export const NETWORK_ERROR = 'Network request failed'
export const NOT_FOUND = "(NotFound(...), 'Not Found')"
export const NOT_FOUND2 = 'Not Found'

// Deprecated
export const handleError = async (errs: GraphqlResponseError[]) => {
  for (const { message } of errs) {
    if (message === NETWORK_ERROR) {
      return new Promise((res) => {
        setTimeout(() => res(true), 1000)
      })
    }
    if (message === TOKEN_WRONG_ERROR) {
      removeToken()
      throw new Error('Unexpected error')
    }

    if (message === TOKEN_NOT_FOUND) {
      removeToken()
      throw new Error('Unexpected error')
    }

    if (message === TOKEN_EXPIRED_ERROR) {
      const tokenData = getToken()
      if (!tokenData) {
        throw new Error('Unexpected error')
      }
      const { token: oldToken } = tokenData
      const newToken = await refreshToken(oldToken)
      if (!newToken) {
        removeToken()
        window.location.reload()
        throw new Error('Unexpected error')
      }
      return true
    }
  }
  return false
}

export const handleTokenRefresh = async () => {
  const tokenData = getToken()
  if (!tokenData) {
    throw new Error('Unexpected error')
  }
  const { token: oldToken } = tokenData
  const newToken = await refreshToken(oldToken)
  if (!newToken) {
    Sentry.captureException(new Error('Refresh failed'))
    removeToken()
    window.location.reload()
    throw new Error('Unexpected error')
  }
}

export const isExpiredTokenError = (errs: GraphqlResponseError[]) => {
  const [{ message }] = errs
  return isExpiredToken(message)
}

export const isExpiredToken = (error?: string) => {
  return error === TOKEN_EXPIRED_ERROR
}

export const isWrongToken = (error?: string) => {
  return error === TOKEN_WRONG_ERROR || error === TOKEN_NOT_FOUND
}

export const isPermissionDenied = (error?: string) => {
  return error === PERMISSION_ERROR
}

export const isNotFound = (error?: string) => {
  return error === NOT_FOUND || error === NOT_FOUND2
}
