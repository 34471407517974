import { User } from '../../types'
import { UserData } from './types'

export const INTERNAL_EMAIL_SUFFIX = '@stylespace.com'

export const transformData = (data: UserData): User => {
  const {
    id,
    userProfile: { fullName, avatar, gender, timezone },
    capabilities: {
      maxSessions,
      canApproveRequests,
      canCheckStyleSubmissionStatus,
      canManageSessions,
    },
  } = data
  return {
    id,
    fullName,
    avatar,
    gender,
    timezone,
    maxSessions,
    isInternal: (data.email as string).endsWith(INTERNAL_EMAIL_SUFFIX),
    isAdmin: canApproveRequests,
    isStylistApplicant: canCheckStyleSubmissionStatus,
    isStylist: canManageSessions,
  }
}
