import Cookies from 'js-cookie'

import { fetch } from './fetch'
import { getDomain, GRAPHQL_URL, TOKEN } from '../utils'

export type Token = {
  expiry: string
  token: string
}

const getRefreshQuery = (token: string) => {
  return `mutation {
  field: refreshAuthToken(token: "${token}") {
    token
    expiry
  }
}`
}

export const refreshQueue: QueueItem[] = []
export let refreshLock = false

class QueueItem {
  resolve: (token: string | null) => void
  promise: Promise<string | null>

  constructor() {
    this.resolve = () => {}
    this.promise = new Promise<string | null>((res) => {
      this.resolve = res
    })
  }
}

export const refreshToken = async (tokenToRefresh: string) => {
  if (refreshLock) {
    const queueItem = new QueueItem()
    refreshQueue.push(queueItem)
    return queueItem.promise
  }

  refreshLock = true

  const data = await fetch(
    GRAPHQL_URL,
    getRefreshQuery(tokenToRefresh),
    {},
  ).catch((e) => {
    console.log(`[Refresh error] ${e}`)
    return null
  })

  if (!data || !data.data.field) {
    while (refreshQueue.length > 0) {
      const item = refreshQueue.pop()
      item!.resolve(null)
    }
    removeToken()
    refreshLock = false
    return null
  }
  const { token, expiry } = data.data.field
  setToken({ token, expiry })
  while (refreshQueue.length > 0) {
    const item = refreshQueue.pop()
    item!.resolve(token)
  }
  refreshLock = false
  return token
}

export const setToken = ({ token, expiry }: Token) => {
  Cookies.set(TOKEN, JSON.stringify({ token, expiry }), {
    domain: getDomain(),
    expires: 30,
  })
}

export const removeToken = () => {
  Cookies.remove(TOKEN, { domain: getDomain() })
}

export const getToken = (): Token | null => {
  if (typeof window === 'undefined') {
    return null
  }
  const stringToken = Cookies.get(TOKEN)
  if (!stringToken) {
    return null
  }
  try {
    const data = JSON.parse(stringToken)
    if (!data || !data.token || !data.expiry) {
      if (data && (!data.token || !data.expiry)) {
        throw new Error('Token not valid')
      }
      return null
    }
    return {
      token: data.token,
      expiry: data.expiry,
    }
  } catch (e: any) {
    removeToken()
    throw new Error(e)
  }
}
