import { DefaultTheme } from '@style-space/components/src/themes'

import { pxToRem } from '../utils/utils'

export const theme = {
  ...DefaultTheme,
  images: {
    calendar: '/images/calendar.png',
    empty: 'images/broken_image.png',
    logoHorizontal: '/images/logo_gray_long.svg',
    logo: '/images/logo_white.svg',
    logoGray: '/images/logo_gray.svg',
    emptyPhoto: '/images/emptyPhoto.svg',
    instagram: '/images/instagram.svg',
    facebook: '/images/facebook.svg',
  },
  breakpoint: {
    tabletV: pxToRem(640),
    tabletH: pxToRem(960),
    laptop: pxToRem(1280),
    desktop: pxToRem(1600),
  },
  smallWidth: pxToRem(920),
  maxWidth: pxToRem(1100),
  maxScreen: pxToRem(1248),
  maxScreenPx: 1280,
}
