export * from '@style-space/components/src/lib/utils/constants'
import { CANONICAL } from '@style-space/components/src/lib/utils/constants'

import {
  Options,
  Category,
  ApplicationStatusCore,
  WeekDay,
} from '../../@types/types'

export const TITLE = 'Style Space || Discover your style'
export const DESCRIPTION =
  'Style Space is a virtual styling and beauty service that connects you 1-on-1 with expert personal stylists to look and feel your best.'

export const METADATA_DESCRIPTION_CAP = 155

export const SEARCH_RESULTS_ID = 'search-results'
export const NODE_ENV = process.env.NODE_ENV
export const ENV = process.env.NEXT_PUBLIC_ENV
export const IS_TEST = process.env.NODE_ENV === 'test'
export const GOOGLE_CAPTCHA_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_KEY || ''
export const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL
export const MAX_BOOKING_DAY = 90

export const CLEAR_VALUE = 'CLEAR'

export const GOOGLE_KEY = process.env.NEXT_PUBLIC_GOOGLE_KEY
export const GOOGLE_SECRET = process.env.GOOGLE_SECRET
export const GOOGLE_REDIRECT = `${CANONICAL}/integrations/google`

export const IMAGES_SIZES = [640, 750, 828, 1080, 1200, 1920, 2048, 3840]

export const MODAL_CLASS = 'modal_open'

export const PREVIEW_ASPECT = 1.91
export const ASPECT_4x3 = 4 / 3
export const ASPECT_3x4 = 3 / 4
export const SESSION_HEADER_RATIO = 118
export const RATIO_3x4 = 133
export const RATIO_4x3 = 73

export const WEEK_DAYS_ARRAY: WeekDay[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
]

export const ORDER_NUMBERS = [
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
  'twenty',
  'twenty one',
  'twenty two',
  'twenty three',
  'twenty four',
  'twenty five',
  'twenty six',
  'twenty seven',
  'twenty eight',
  'twenty nine',
  'thirty',
  'thirty one',
  'thirty two',
  'thirty three',
  'thirty four',
  'thirty five',
]

export const WEEK_DAYS_OPTIONS: {
  label: string
  value: WeekDay
}[] = WEEK_DAYS_ARRAY.map((value) => ({
  label: value[0] + value.toLowerCase().slice(1),
  value,
}))

export const CATEGORIES_MAP: { [key in Category]: string } = {
  HAIR: 'Hair',
  CLOTHING: 'Clothing',
  MAKE_UP_SKIN_CARE: 'Makeup and Skincare',
  ANY: 'Any',
}

export const CATEGORY_STYLIST: { [key in Category]: string } = {
  HAIR: 'Hair stylist',
  CLOTHING: 'Clothing stylist',
  MAKE_UP_SKIN_CARE: 'Makeup and Skincare stylist',
  ANY: 'Any stylist',
}

export const SPECIALIZATIONS = [
  { value: 'MAKE_UP_SKIN_CARE', label: 'Makeup/Skincare' },
  { value: 'HAIR', label: 'Hair' },
  { value: 'CLOTHING', label: 'Clothing' },
]

export const APPLICATION_STATUS_MAP: {
  [key in ApplicationStatusCore]: string
} = {
  SUBMITTED: 'Submitted',
  SAVED_IN_PROGRESS: 'Saved',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
}

export const LICENSES_OPTIONS = [
  {
    value: 'I_HAVE_ALL_REQUIRED_LICENSES',
    label: 'I have all the necessary licenses to conduct my style session',
  },
  {
    value: 'I_DO_NOT_REQUIRE_A_LICENSE',
    label: 'I do not require licenses to conduct my style session',
  },
]

export const EXPERIENCE = [
  { value: 1, label: '1 year' },
  ...new Array(33)
    .fill(null)
    .map((_, i) => ({ value: i + 2, label: `${i + 2} years` })),
  { value: 35, label: '35+ years' },
] as unknown as Options

export const INTERNET_SPEED = [
  { value: 'MBPS_50_PLUS', label: '50+ Mbps' },
  { value: 'MBPS_25_TO_50', label: '25-50 Mbps' },
  { value: 'MBPS_1_TO_25', label: '1-24 Mbps' },
]

export const LANGUAGE_LEVEL = [
  { value: 'NATIVE_OR_BILINGUAL', label: 'Native or bilingual proficiency' },
  {
    value: 'FULL_PROFESSIONAL',
    label: 'Full professional working proficiency',
  },
  { value: 'LIMITED_WORKING', label: 'Limited working proficiency' },
  { value: 'ELEMENTARY', label: 'Elementary proficiency' },
]

export const LANGUAGES = ['English', 'French', 'Polish']
export const CURRENCIES = ['USD', 'CZK', 'EUR']

export const SOCIALS = [
  { label: 'Facebook', value: 'FACEBOOK' },
  { label: 'Instagram', value: 'INSTAGRAM' },
  { label: 'Tiktok', value: 'TIKTOK' },
  { label: 'Youtube', value: 'YOUTUBE' },
  { label: 'Twitter', value: 'TWITTER' },
  { label: 'Pinterest', value: 'PINTEREST' },
  { label: 'Other', value: 'OTHER' },
]

export const DURATIONS = [
  { label: '30m', value: `30` },
  { label: '45m', value: `45` },
  { label: '1h', value: `60` },
  { label: '1h30m', value: `90` },
]

export const GENDER = [
  { label: 'Female', value: 'FEMALE' },
  { label: 'Male', value: 'MALE' },
]
export const ANY_GENDER = [...GENDER, { label: 'Any', value: 'ANY' }]

export const GOALS = [
  { value: 'Define my personal style', label: 'Define my personal style' },
  { value: 'Get expert style advice', label: 'Get expert style advice' },
  { value: 'Improve my look', label: 'Improve my look' },
  { value: 'Gain confidence in my look', label: 'Gain confidence in my look' },
  {
    value: 'Discover new styles that best suit me',
    label: 'Discover new styles that best suit me',
  },
  { value: 'Save time getting ready', label: 'Save time getting ready' },
  { value: 'Other', label: 'Other' },
]
