import React from 'react'
import { Footer as MasterFooter } from '@style-space/components/src/Footer'
import styled, { css } from 'styled-components'
import { useUser } from '@style-space/components/src/Context/AuthContext/hooks/useUser'

const LOGO = '/images/logo_white.svg'
const FACEBOOK = '/images/facebook_icon.svg'
const INSTAGRAM = '/images/instagram_icon.svg'

const SMasterFooter = styled(MasterFooter)(
  ({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      &.bottom-padding {
        padding-bottom: 6rem;
      }
    }
  `,
)

type Props = {
  bottomPadding?: boolean
}

export const Footer: React.FC<Props> = ({ bottomPadding }) => {
  const { data: user } = useUser()
  const bottomPaddingClass = bottomPadding ? 'bottom-padding' : ''
  return (
    <SMasterFooter
      logo={LOGO}
      withStylist={user?.isStylist}
      facebookLogo={FACEBOOK}
      instagramLogo={INSTAGRAM}
      className={bottomPaddingClass}
    />
  )
}
