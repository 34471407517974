import { DAY, DayJs, HOUR } from './constants'
import { getTime } from './time'
import { DateData, DurationUnitType } from './types'

export const DAY_TIME_FORMAT = 'h:mma'
export const DAY_ISO_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT = 'ddd, D MMM'
export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS'

export const formatDate = (
  date: DateData,
  format: string,
  timezone?: string,
) => {
  return DayJs(date).tz(timezone).format(format)
}

export const formatRawDate = (date: DateData, format: string) => {
  return DayJs(date).format(format)
}

export const formatDayTimeInMinutes = (minutes: number, format: string) => {
  const time = DayJs(0).add(minutes, 'minute')
  return formatDate(time, format, 'utc')
}

export const toHoursFormat = (minutes: number) => {
  if (minutes % HOUR) {
    return formatDayTimeInMinutes(minutes, `h:mm a`)
  }

  return formatDayTimeInMinutes(minutes, 'h a')
}

export const toDurationFormat = (
  amount: number,
  fromFormat: DurationUnitType,
  toFormat: DurationUnitType,
) => {
  return DayJs.duration(amount, fromFormat).as(toFormat)
}

export const formatDayISO = (date: DateData) => {
  return formatDate(date, DAY_ISO_FORMAT)
}

export const formatRawDay = (date: DateData) => {
  return formatRawDate(date, DAY_ISO_FORMAT)
}

export const formatISO = (date: DateData, timezone: string = 'UTC') => {
  const timezoneString =
    timezone === 'UTC' ? 'Z' : formatDate(date, 'Z', timezone)
  return `${formatDate(date, ISO_FORMAT, timezone)}${timezoneString}`
}

export const getAgoString = (date: DateData, limitOfDays?: number) => {
  const dateValue = getTime(date)
  const nowValue = getTime()
  const duration = DayJs.duration(dateValue - nowValue, 'millisecond')
  const days = duration.asDays()
  const minutes = duration.asMinutes()
  if (minutes < 1 && minutes > -1) {
    return 'now'
  }
  if (limitOfDays && Math.abs(days) >= limitOfDays) {
    return formatDate(date, DATE_FORMAT)
  }
  return duration.humanize(true)
}

export const getDurationString = (minutes: number) => {
  const hours = Math.floor(minutes / HOUR)
  const rest = minutes % HOUR

  return `${hours >= 1 ? `${hours}h` : ''}${rest >= 1 ? `${rest}m` : ''}`
}

export const getLongDurationString = (minutes: number) => {
  const duration = DayJs.duration(minutes, 'minute')
  const hours = duration.hours()
  const rest = duration.minutes()

  const hourString = hours > 1 ? 'hours' : 'hour'
  const minuteString = rest > 1 ? 'minutes' : 'minute'

  return `${hours > 0 ? `${hours} ${hourString}` : ''}${
    rest > 0 ? `${hours > 0 ? ' ' : ''}${rest} ${minuteString}` : ''
  }`
}

export const DAY_TIME_OPTIONS = (() => {
  const halfHour = HOUR / 2
  const halfHoursInADay = DAY / halfHour + 1
  return new Array(halfHoursInADay).fill(null).map((_, i) => ({
    value: i * halfHour,
    label: toHoursFormat(i * halfHour),
  }))
})()
export const DAY_TIME_MIDDLE_INDEX = Math.floor(DAY_TIME_OPTIONS.length / 2)
