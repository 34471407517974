export type GraphqlResponseError = { message: string }
export class GraphQlError extends Error {
  errors: { message: string }[] = []
  constructor(
    key: string,
    message: string,
    errors: GraphqlResponseError[] = [],
  ) {
    super(`[${key}]: ${message} ${JSON.stringify(errors)}`)
    this.name = 'GraphqlError'
    this.errors = errors
  }
}
