import React, { HTMLAttributes, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { DefaultTheme } from '../themes'
import { SButton } from './Button.style'

type ButtonType = 'primary' | 'secondary' | 'redButton' | 'inline-link' | 'link'

export type Props = HTMLAttributes<HTMLButtonElement> & {
  className?: string
  buttonType?: ButtonType
  buttonSize?: 'regular' | 'small'
  type?: 'submit' | 'button'
  iconSrc?: string
  uppercase?: boolean
  disabled?: boolean
  iconSvg?: JSX.Element
  text?: string
}

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      className = '',
      buttonType = '',
      buttonSize = '',
      type = 'button',
      iconSrc = '',
      uppercase = true,
      disabled,
      iconSvg,
      text,
      children,
      ...rest
    },
    ref,
  ) => {
    const theme = useContext(ThemeContext) || DefaultTheme
    return (
      <SButton
        className={`${buttonType} ${buttonSize} ${className} ${
          text || children ? '' : 'iconOnly'
        } ${uppercase && 'uppercase'}`}
        type={type}
        disabled={disabled}
        theme={theme}
        data-testid="button"
        ref={ref}
        {...rest}
      >
        {iconSrc && (
          <img
            className="button-icon"
            src={iconSrc}
            alt=""
            data-testid="button-icon"
          />
        )}
        {iconSvg}
        {text}
        {children}
      </SButton>
    )
  },
)
Button.displayName = 'Button'
