import React, { HTMLAttributes, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { DefaultTheme } from '../themes'
import { A, SNextLink } from './Anchor.style'

type AnchorType =
  | ''
  | 'inline'
  | 'button'
  | 'button primary regular'
  | 'button primary small'
  | 'button secondary regular'
  | 'button secondary small'

export type Props = HTMLAttributes<HTMLAnchorElement> & {
  anchorType?: AnchorType
  target?: '_blank'
  href?: string
  className?: string
  uppercase?: boolean
  nextLink?: boolean
  download?: string
  replace?: boolean
  underline?: boolean
  'data-testid'?: string
  bold?: boolean
  children: any
}

export const Anchor: React.FC<Props> = React.forwardRef<
  HTMLAnchorElement,
  Props
>(
  (
    {
      anchorType = '',
      className = '',
      children,
      href,
      uppercase = false,
      download,
      underline = false,
      bold = false,
      nextLink = false,
      ...rest
    },
    ref,
  ) => {
    const theme = useContext(ThemeContext) || DefaultTheme
    const props = {
      className: `${className} ${anchorType}
        ${uppercase && 'uppercase'} ${underline && 'underline'} ${
          bold && 'bold'
        }`,
      theme,
      ref,
      'data-testid': 'anchor',
      download,
      ...rest,
    }
    if (nextLink && href) {
      return (
        <SNextLink href={href || ''} {...props}>
          {children}
        </SNextLink>
      )
    }
    return (
      <A href={href} {...props}>
        {children}
      </A>
    )
  },
) as any
Anchor.displayName = 'Anchor'
