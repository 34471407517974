import React, { useContext, Fragment } from 'react'
import Dialog from 'react-modal'
import { ThemeContext } from 'styled-components'
import { Transition } from '@headlessui/react'

import {
  SHeading,
  DialogWrap,
  DialogInner,
  SXIcon,
  SOverlay,
  XButton,
} from './Modal.style'
import { DefaultTheme } from '../themes'
import { useMixpanel } from '../lib/mixpanel/useMixpanel'
import { IS_TEST } from '../lib/utils'

export const MODAL_CLASS = 'modal_open'
const EVENT_NAME = 'View Modal'
const EVENT_MODAL_NAME = 'Modal Name'

type Props = {
  modalName: string
  title?: string | JSX.Element
  lineTitle?: boolean
  isOpen?: boolean
  withX?: boolean
  style?: any
  animType?: 'defaultAnim' | 'bottomupAnim'
  holdOpen?: boolean
  onClose?(): void
  className?: string
  children?: React.ReactNode
}

export const Modal: React.FC<Props> = ({
  modalName,
  className = '',
  onClose = () => {},
  holdOpen,
  style,
  animType = '',
  lineTitle,
  withX = true,
  title,
  isOpen = false,
  children,
}) => {
  const { trackEvent } = useMixpanel()
  const titleRef = React.useRef<HTMLHeadingElement | null>(null)
  const theme = useContext(ThemeContext) || DefaultTheme
  const offset = React.useRef(0)
  React.useEffect(() => {
    if (isOpen && offset.current) {
      return
    }
    if (isOpen) {
      offset.current = window.scrollY
      window.scrollTo(0, 0)
      document.body.style.top = `-${offset.current}px`
      document.body.classList.add(MODAL_CLASS)
    }
    return () => {
      if (isOpen) {
        document.body.classList.remove(MODAL_CLASS)
        document.body.style.top = `0px`
        window.scrollTo(0, offset.current)
        offset.current = 0
      }
    }
  }, [isOpen])

  React.useEffect(() => {
    if (isOpen) {
      trackEvent(EVENT_NAME, { [EVENT_MODAL_NAME]: modalName })
    }
  }, [isOpen, modalName])

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      const elem = document.getElementById('__next')
      if (elem) {
        Dialog.setAppElement(elem)
      }
    }
  })

  return (
    <Transition show={isOpen}>
      <Dialog
        className={`headless-modal modal ${className} ${animType}`}
        isOpen={isOpen}
        ariaHideApp={!IS_TEST}
        style={{
          overlay: {
            zIndex: 9999,
            backgroundColor: 'transparent',
          },
        }}
        onRequestClose={onClose}
      >
        <DialogWrap
          theme={theme}
          className={`headless-modal-wrap modal-wrap`}
          data-testid="headless-modal-wrap"
        >
          <Transition.Child
            as={Fragment}
            enter="enter"
            enterFrom="enterFrom"
            enterTo="enterTo"
          >
            <SOverlay
              theme={theme}
              className="headless-modal-overlay modal-overlay"
              onClick={holdOpen ? undefined : onClose}
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="enter"
            enterFrom="enterFrom"
            enterTo="enterTo"
          >
            <DialogInner
              theme={theme}
              style={style}
              className={`headless-modal-inner-wrap modal-inner-wrap`}
              data-testid="headless-modal-inner-wrap"
            >
              {title && (
                <SHeading
                  headingType="style3"
                  line={lineTitle}
                  ref={titleRef}
                  type="h1"
                  data-testid="headless-modal-title"
                  className="headless-modal-title modal-title"
                >
                  {title}
                </SHeading>
              )}
              {children}
              {withX && (
                <XButton
                  theme={theme}
                  data-testid="headless-modal-x"
                  className="headless-modal-x modal-x"
                  onClick={onClose}
                >
                  <SXIcon theme={theme} />
                </XButton>
              )}
            </DialogInner>
          </Transition.Child>
        </DialogWrap>
      </Dialog>
    </Transition>
  )
}
Modal.displayName = 'HeadlessModal'
