import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import {
  EXTERNAL_ROUTES,
  GIFT_UP_ROUTE,
  LEGAL_DOCUMENTS_ROUTES,
  ROUTES,
  getSitemapRoute,
  getFAQRoute,
} from '../lib/utils/routes'
import {
  LinkWrap,
  Link,
  InnerWrap,
  Line,
  LinkTitle,
  LogoWrap,
  Logo,
  Icon,
  Nav,
  SocialLink,
  SocialWrap,
  LogoText,
  SFooter,
  SImage,
  LeftWrap,
  CertWrap,
} from './Footer.style'
import { DefaultTheme } from '../themes'
import SDVOSBLogo from '../../public/cert-sdvosb.png'

type Props = {
  logo: string
  facebookLogo: string
  instagramLogo: string

  withStylist?: boolean
  className?: string
}

export const Footer: React.FC<Props> = ({
  logo,
  instagramLogo,
  facebookLogo,
  withStylist,
  className = '',
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <SFooter theme={theme} data-testid="footer" className={className}>
      <InnerWrap theme={theme}>
        <LeftWrap theme={theme}>
          <LogoWrap theme={theme}>
            <Line theme={theme} />
            <Logo theme={theme} alt="Style Space logo" src={logo} />
            <LogoText theme={theme}>© 2024, Style Space</LogoText>
          </LogoWrap>
          <CertWrap theme={theme}>
            <SImage theme={theme} src={SDVOSBLogo} alt="" />
          </CertWrap>
        </LeftWrap>
        <LinkWrap theme={theme}>
          <Nav theme={theme}>
            <LinkTitle theme={theme}>THE COMPANY</LinkTitle>
            <Link href={ROUTES.about} theme={theme} data-testid="footerAbout">
              About us
            </Link>
            <Link
              href={ROUTES.howItWorks}
              theme={theme}
              data-testid="footerHotItWorks"
            >
              How it works
            </Link>
            <Link
              href={ROUTES.contact}
              theme={theme}
              data-testid="footerContacts"
            >
              Contact
            </Link>
            <Link href={getFAQRoute()} theme={theme} data-testid="footerFaq">
              FAQ
            </Link>
            <Link
              href={ROUTES.becomeStylist}
              theme={theme}
              data-testid="footerBecomeStylist"
            >
              Become a Stylist
            </Link>
            {GIFT_UP_ROUTE && (
              <Link
                aria-label="Gift cards"
                theme={theme}
                href={GIFT_UP_ROUTE}
                data-testid="footerBecomeStylist"
              >
                Gift cards
              </Link>
            )}
          </Nav>
          <Nav>
            <LinkTitle theme={theme}>DOCUMENTS</LinkTitle>
            <Link
              href={LEGAL_DOCUMENTS_ROUTES.termsOfService}
              theme={theme}
              target="_blank"
              data-testid="footerT&C"
            >
              Terms & Conditions
            </Link>
            {withStylist && (
              <Link
                href={LEGAL_DOCUMENTS_ROUTES.stylistTermsOfService}
                target="_blank"
                theme={theme}
                data-testid="footerStylistT&C"
              >
                Stylists Terms & Conditions
              </Link>
            )}
            <Link
              href={LEGAL_DOCUMENTS_ROUTES.privacyPolicy}
              theme={theme}
              target="_blank"
              data-testid="footerPrivacyPolicy"
            >
              Privacy Policy
            </Link>
            <Link
              href={LEGAL_DOCUMENTS_ROUTES.cookiePolicy}
              target="_blank"
              theme={theme}
              data-testid="footerCookiePolicy"
            >
              Cookie Policy
            </Link>
            <Link
              href={LEGAL_DOCUMENTS_ROUTES.GDPRPolicy}
              target="_blank"
              theme={theme}
              data-testid="footerGDPRNotice"
            >
              GDPR Notice
            </Link>
            <Link
              href={getSitemapRoute()}
              theme={theme}
              data-testid="footerSitemap"
            >
              Sitemap
            </Link>
          </Nav>
        </LinkWrap>
        <SocialWrap theme={theme}>
          <SocialLink
            href={EXTERNAL_ROUTES.facebook}
            theme={theme}
            aria-label="facebook page link"
            data-testid="footer-facebook"
          >
            <Icon theme={theme} alt="Facebook logo" src={facebookLogo} />
          </SocialLink>
          <SocialLink
            href={EXTERNAL_ROUTES.instagram}
            theme={theme}
            aria-label="instagram page link"
            data-testid="footer-instagram"
          >
            <Icon theme={theme} alt="Instagram logo" src={instagramLogo} />
          </SocialLink>
        </SocialWrap>
      </InnerWrap>
    </SFooter>
  )
}
Footer.displayName = 'Footer'
