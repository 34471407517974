import { QueryFunctionContext } from 'react-query'
import crossFetch from 'cross-fetch'

import { UnexpectedError } from '../error'
import { GraphQlError, GraphqlResponseError } from '../error/GraphQlError'
import { BACKEND_URL } from '../utils'
import { getToken } from './token'
import { getAuthHeaders } from './utils'
import { handleTokenRefresh, isExpiredTokenError } from './error'

export type GraphqlResponse<T> = { data: T; errors: GraphqlResponseError[] }

export const fetch = <T = any>(
  url: string,
  query: string,
  headers: { [key: string]: string } = {},
  variables: { [key: string]: string | number | boolean } = {},
  signal?: AbortSignal,
  ignoreGraphqlErrors?: boolean,
): Promise<T> => {
  const key = `${query.slice(0, 10)}`
  return crossFetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      ...headers,
    },
    body: JSON.stringify({
      query: query.trim().split(/\s+/).join(' '),
      variables,
    }),
    signal,
  })
    .then(async (res) => {
      return res.json() as Promise<GraphqlResponse<T>>
    })
    .then(({ data, errors }) => {
      if (!ignoreGraphqlErrors && errors.length > 0) {
        throw new GraphQlError(key, 'Graphql Api error', errors)
      }
      return data
    })
}

export type AnyVariables = { [key: string]: string | number | boolean }
export type QueryKey = [string, AnyVariables]

export type FetchParams = {
  url?: string
  query?: string
  headers: { [key: string]: string }
  paged?: boolean
  ignoreGraphqlErrors?: boolean
}

export function useMutationFn<T, V = AnyVariables>({
  query,
  url = BACKEND_URL,
  headers,
}: FetchParams) {
  const mutationFn = async (variables: V): Promise<T> => {
    if (!url || !query) {
      throw new UnexpectedError('Url or query is not defined')
    }
    const token = getToken()
    return fetch<T>(
      url,
      query,
      {
        ...headers,
        ...(token ? getAuthHeaders(token) : {}),
      },
      variables || {},
    ).catch((e) => {
      if (e instanceof GraphQlError && isExpiredTokenError(e.errors)) {
        return handleTokenRefresh().then(() => {
          return mutationFn(variables)
        })
      }
      throw e
    })
  }
  return mutationFn
}

export function useQueryFn<T>({
  query,
  url = BACKEND_URL,
  headers,
  paged,
  ignoreGraphqlErrors,
}: FetchParams) {
  const queryFn = async (
    params: QueryFunctionContext<QueryKey, number>,
  ): Promise<T> => {
    if (!url || !query) {
      throw new UnexpectedError('Url or query is not defined')
    }
    const token = getToken()
    const [, variables] = params.queryKey
    return fetch<T>(
      url,
      query,
      {
        ...headers,
        ...(token ? getAuthHeaders(token) : {}),
      },
      {
        ...variables,
        ...(paged ? { offset: params.pageParam || 0 } : {}),
      },
      params.signal,
      ignoreGraphqlErrors,
    ).catch((e) => {
      if (e instanceof GraphQlError && isExpiredTokenError(e.errors)) {
        return handleTokenRefresh().then(() => {
          return queryFn(params)
        })
      }
      throw e
    })
  }
  return queryFn
}
