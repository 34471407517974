import React, { useEffect, useMemo, useReducer } from 'react'

import { ContextData } from './types'
import { authReducer } from './reducer'
import { getData, storeData } from './preserve'
import { selectMode } from './selectors'

export const DefaultContextData: ContextData = {
  state: {
    user: null,
    mode: null,
    timezone: null,
  },
  dispatch: () => {
    throw new Error('Dispatch is not defined')
  },
}

export const AuthContext = React.createContext<ContextData>(DefaultContextData)

type Props = {
  children: React.ReactNode
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, null, () => ({
    user: null,
    mode: null,
    timezone: null,
    ...getData(),
  }))

  const mode = selectMode(state)

  const value = useMemo(
    (): ContextData => ({
      state,
      dispatch,
    }),
    [state, dispatch],
  )

  useEffect(() => {
    storeData({ mode })
  }, [mode])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
